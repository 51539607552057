import { Layout } from 'antd';
import React from 'react';
import { Switch, Route } from 'react-router-dom';

import AppFooter from './AppFooter';
import AppHeader from './AppHeader';
import NotFound from '../Common/NotFound';
import NotAuthorized from '../Common/NotAuthorized';
import ChartController from '../Chart/ChartController';
import CamerasController from '../Cameras/CamerasController';
import DispatchController from '../Dispatch/DispatchController';
import PreferencesPage from '../Preferences/PreferencesPage';
import AccountController from '../Account/AccountController';
import LocationController from '../Locations/LocationController';
import CompanyController from '../Companies/CompanyController';

const { Header, Content, Footer } = Layout;

const AppLayout = (props) => (
  <Layout className="full-layout">
    <Header>
      {/* <div className="logo" /> */}
      <AppHeader {...props} />
    </Header>
    <Content>
      <Switch>
        <Route exact path="/companies" render={() => (props.isCanforAdmin ? <CompanyController {...props} /> : <NotAuthorized />)} />
        <Route exact path="/locations" render={() => (props.isCanforAdmin ? <LocationController {...props} /> : <NotAuthorized />)} />
        <Route exact path="/preferences" render={() => (props.selectedCompany.isDispatcher ? <PreferencesPage {...props} /> : <NotAuthorized />)} />
        <Route exact path="/cameras" render={() => (props.selectedCompany.isDispatcher ? <CamerasController {...props} /> : <NotAuthorized />)} />
        <Route exact path="/dispatch" render={() => <DispatchController {...props} />} />
        <Route exact path="/chart/:binBank" render={() => (props.selectedCompany.isDispatcher ? <ChartController {...props} /> : <NotAuthorized />)} />
        <Route exact path="/chart/" render={() => (props.selectedCompany.isDispatcher ? <ChartController {...props} /> : <NotAuthorized />)} />
        <Route exact path="/account" render={() => (props.selectedCompany.isDispatcher ? <AccountController {...props} /> : <NotAuthorized />)} />
        <Route exact path="/" render={() => <DispatchController {...props} />} />
        <Route component={NotFound} />
      </Switch>
    </Content>
    <Footer>
      <AppFooter />
    </Footer>
  </Layout>
);

export default AppLayout;
