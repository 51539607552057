import { Table, Checkbox } from 'antd';
import _ from 'lodash';
import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';

import ColorPopover from '../Common/ColorPopover';

const BinBankPreferencesTable = (props) => {
  const {
    filters,
    loading,
    savingPreferences,
    selectedRowKeys,
    rows,
    size,

    onColorChange,
    onRowSelectChange,
    onTableChange,
    onVisibleCheck,
  } = props;

  const columns = [
    {
      title: 'Site',
      dataIndex: 'siteName',
      filteredValue: filters.siteName || null,
      filters: _.sortBy(_.uniqBy(rows, 'siteName').map((x) => ({ text: x.siteName, value: x.siteName })), (x) => x.text),
      onFilter: (filterValue, record) => record.siteName.indexOf(filterValue) === 0,
      width: 160,
    },
    {
      title: 'MC Group',
      dataIndex: 'mcGroupDescription',
      // className: 'align-center-column',
      filteredValue: filters.mcGroupDescription || null,
      filters: _.sortBy(_.uniqBy(rows, 'mcGroupDescription').map((x) => ({ text: x.mcGroupDescription, value: x.mcGroupDescription })), (x) => x.text),
      onFilter: (filterValue, record) => record.mcGroupDescription.indexOf(filterValue) === 0,
      width: 110,
    },
    {
      title: 'Bin Bank',
      dataIndex: 'binBankDescription',
      filteredValue: filters.binBankDescription || null,
      filters: _.sortBy(_.uniqBy(rows, 'binBankDescription').map((x) => ({ text: x.binBankDescription, value: x.binBankDescription })), (x) => x.text),
      onFilter: (filterValue, record) => record.binBankDescription.indexOf(filterValue) === 0,

    },
    // {
    //   title: 'Bin Description',
    //   dataIndex: 'bin',
    //   filteredValue: filters.bin || null,
    //   filters: _.sortBy(_.uniqBy(rows, 'bin').map(x => ({ text: x.bin, value: x.bin })), x => x.text),
    //   onFilter: (filterValue, record) => record.bin.indexOf(filterValue) === 0,
    // },
    {
      title: 'Product',
      dataIndex: 'product',
      // className: 'align-center-column',
      filteredValue: filters.product || null,
      filters: _.sortBy(_.uniqBy(rows, 'product').map((x) => ({ text: x.product, value: x.product })), (x) => x.text),
      onFilter: (filterValue, record) => record.product.indexOf(filterValue) === 0,
      width: 100,
    },
    {
      title: 'Visible',
      dataIndex: 'visible',
      className: 'align-center-column',
      render: (visible, row) => {
        if (_.includes(savingPreferences, row.binBankId)) {
          return <LoadingOutlined />;
        }
        return (
          <Checkbox
            checked={visible}
            onChange={(e) => onVisibleCheck(row.binBankId, e.target.checked)}
          />
        );
      },
      filteredValue: filters.visible || null,
      filters: [{ text: 'Hidden', value: 'Hidden' }, { text: 'Visible', value: 'Visible' }],
      onFilter: (filterValue, record) => record.visible === (filterValue === 'Visible'),
      width: 100,
    },
    {
      title: 'Colour',
      dataIndex: 'color',
      className: 'align-center-column',
      style: { margin: 'auto' },
      render: (color, row) => {
        if (_.includes(savingPreferences, row.binBankId)) {
          return <LoadingOutlined />;
        }
        return (
          <ColorPopover
            color={color}
            onColorChange={(newColor) => onColorChange(row.binBankId, newColor)}
          />
        );
      },
      width: 100,
    },
  ];

  const rowSelectionOptions = {
    selectedRowKeys,
    onChange: onRowSelectChange,
  };

  return (
    <div className="mywrapperthing" style={{ height: '100%' }}>
      <Table
        bordered
        columns={columns}
        dataSource={rows}
        loading={loading}
        rowKey="binBankId"
        rowSelection={rowSelectionOptions}
        onChange={onTableChange}
        size="small"
        locale={{
          emptyText: 'This company has not been granted access to any bin banks.',
        }}
        pagination={false}
        scroll={{ y: size.height - 48 }}
        style={{ height: size.height - 48 }}
      />
    </div>
  );
};

export default BinBankPreferencesTable;
