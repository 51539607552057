import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import './style.scss';
import AuthorizedApp from './App/AuthorizedApp';

ReactDOM.render(<BrowserRouter><AuthorizedApp /></BrowserRouter>, document.getElementById('root'));
