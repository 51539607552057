import React from 'react';
import { Row, Col } from 'antd';
import BinBankCard from './BinBankCard';

const SiteCard = ({ product, localTimezone }) => {
  const binBanks = product.binBanks.map((bb) => <Col style={{ padding: 16 }} xs={24} lg={12} xxl={8}><BinBankCard product={product.product} binBank={bb} localTimezone={localTimezone} /></Col>);

  return (
    <>
      <Row>
        {binBanks}
      </Row>
    </>
  );
};

export default SiteCard;
