import React from 'react';
import {
  Select, Table, Button, Popconfirm,
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

const { Option } = Select;

const DependencyTable = (props) => {
  const {
    disabled, location, locations, onAddDependency, onEditDependency, onDeleteDependency,
  } = props;

  const takenLocations = location.dependencies.map((d) => d.locationId);

  const columns = [
    {
      title: 'Scale',
      dataIndex: 'locationId',
      width: '30%',
      render: (locationId, record) => (
        <Select notFoundContent="No Dumpers to add" disabled={disabled} style={{ width: 240 }} value={locationId} onChange={(event) => onEditDependency(record.dependencyId, event, 'locationId')}>
          {locations.filter((l) => l.locationId === locationId || (l.locationType === 'Scale' && !takenLocations.includes(l.locationId))).map((s) => <Option key={s.locationId} value={s.locationId}>{s.locationName}</Option>)}
        </Select>
      ),
    },
    {
      title: '',
      dataIndex: 'operation',
      render: (text, record) => (
        <Popconfirm disabled={disabled} title="Are you sure you want to delete?" onConfirm={() => onDeleteDependency(record.dependencyId)}>
          <Button icon={<DeleteOutlined />} />
        </Popconfirm>
      ),
    },
  ];

  // handleDelete = cameraId => {
  //   const dataSource = [...this.state.dataSource];
  //   this.setState({
  //     dataSource: dataSource.filter(item => item.cameraId !== cameraId),
  //   });
  // };

  // handleSave = row => {
  //   const newData = [...this.state.dataSource];
  //   const index = newData.findIndex(item => row.key === item.key);
  //   const item = newData[index];
  //   newData.splice(index, 1, { ...item, ...row });
  //   this.setState({
  //     dataSource: newData,
  //   });
  // };

  // const { dataSource } = this.state;
  // const components = {
  //   body: {
  //     row: EditableRow,
  //     cell: EditableCell,
  //   },
  // };
  // const columns = this.columns.map(col => {
  //   if (!col.editable) {
  //     return col;
  //   }

  //   return {
  //     ...col,
  //     onCell: record => ({
  //       record,
  //       editable: col.editable,
  //       dataIndex: col.dataIndex,
  //       title: col.title,
  //       handleSave: this.handleSave,
  //     }),
  //   };
  // });
  return (
    <div>
      <Button
        disabled={disabled}
        onClick={onAddDependency}
        // type="primary"
        style={{
          marginBottom: 16,
        }}
      >
        Add Dependency
      </Button>
      <Table
        size="small"
        rowKey="dependencyId"
        locale={{
          emptyText: 'No dependencies have been setup for this location',
        }}
        // components={components}
        // rowClassName={() => 'editable-row'}
        bordered
        dataSource={location.dependencies}
        columns={columns}
        pagination={false}
      />
    </div>
  );
};

export default DependencyTable;
