/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import {
  Card, Col, Popconfirm, Row, Switch, Tag, Radio, Button,
} from 'antd';
import React from 'react';
import days from '../Common/Days';

const displaySchedule = (schedule, offset) => {
  if (schedule === null || schedule.schedules === null) {
    return 'No scheduled downtime';
  }

  const lines = schedule.schedules.map(({
    startDay, startTime, endDay, endTime,
  }) => {
    return (
      <tr>
        <td style={{ paddingLeft: '4px', paddingRight: '4px', textAlign: 'center', border: '1px solid black' }}>
          {days.find((d) => d.key === startDay).name}
          {' '}
          {startTime}
        </td>
        <td style={{ paddingLeft: '4px', paddingRight: '4px', textAlign: 'center', border: '1px solid black' }}>
          {days.find((d) => d.key === endDay).name}
          {' '}
          {endTime}
        </td>

      </tr>
    );
    return `${days.find((d) => d.key === startDay).name} ${startTime} to ${days.find((d) => d.key === endDay).name} ${endTime}`;
    // return `${days.find(d => d.key === startDay).name} ${startTime} to ${days.find(d => d.key === endDay).name} ${endTime}`;
  });

    return (
      <table style={{ border: '1px solid rgb(205, 209, 209)' }}>
      <thead>
        <tr>
          <td style={{ textAlign: 'center', border: '1px solid black' }} colSpan={2}>
            <b>
              Scheduled Downtime<br/>
              {offset}
            </b>
          </td>
        </tr>
        <tr>
          <td style={{ cellPadding: '8px', textAlign: 'center', border: '1px solid black' }} colSpan={1}><>Start</></td>
          <td style={{ textAlign: 'center', border: '1px solid black' }} colSpan={1}><>End</></td>
        </tr>
      </thead>
      <tbody>
        {lines}
      </tbody>
    </table>
  );
};

const CameraCard = ({
  location, onImageClick, onStatusChange, canSetStatus, mobile, onEditNotesClick,
}) => {
  const {
    locationId, locationName, status, effectiveStatus, cameras, offset, notes,
  } = location;

  let statusContent;

  if (effectiveStatus.includes('Online')) {
    statusContent = <Tag className="scale-online-tag">{effectiveStatus}</Tag>; //  color="#87d068"
  } else {
    statusContent = <Tag className="scale-offline-tag">{effectiveStatus}</Tag>; // color="#f50"
  }

  let locationSpan;
  let noteSpan;
  let cameraSpan;

  if (!mobile) {
    locationSpan = 3;
    noteSpan = 3;

    switch (cameras.length) {
      case 1:
        cameraSpan = 8;
        break;
      case 2:
        cameraSpan = 8;
        break;
      case 3:
        cameraSpan = 6;
        break;
      case 4:
        cameraSpan = 4;
        break;
    }
  } else {
    locationSpan = 24;
    noteSpan = 0;
    cameraSpan = 24;
  }

  const cameraCards = cameras.map((c) => {
    const {
      camera, cameraId, filename, imgSrc,
    } = c;

    return (
      <Col span={cameraSpan}>
        <div className="camera-item" style={{ margin: 8 }}>
          <p>
            <b>{camera}</b>
          </p>
          <img
            alt={imgSrc ? filename : ` Unable to load ${filename}`}
            src={imgSrc}
            style={{
              height: '300px',
              marginBottom: '16px',
              width: '100%',
            }}
            onClick={() => onImageClick(locationId, cameraId)}
          />
        </div>
        {/* <Card size="small" title={camera}>

        </Card> */}
      </Col>
    );
  });

  return (
    <div className="camera-item dispatch-card">
      <Row>
        <Col span={locationSpan}>
          <p><b>{locationName}</b></p>
          <p>
            {statusContent}
          </p>
          <p>
            <div>
              {displaySchedule(location.effectiveSchedule, offset)}
            </div>
          </p>
          {canSetStatus && (
          <p>
              <span style={{ marginRight: 8 }}>Status:</span>
                <Radio.Group buttonStyle="solid" value={status}>
                  <Popconfirm
                    okText="Confirm and Override"
                    placement="topLeft"
                    title={(
                      <>
                        <p>Changing this to to Auto will enable the weekly/default schedule.</p>
                        <p>Are you sure you want to enable schedules?</p>
                      </>
                    )}
                    onConfirm={() => onStatusChange(locationId, 'Auto')}
                  >
                    <Radio.Button value="Auto">Auto</Radio.Button>
                  </Popconfirm>
                  <Popconfirm
                    okText="Confirm and Override"
                    placement="topLeft"
                    title={(
                      <>
                        <p>Changing this to On will override the weekly/default schedule until it is set back to Auto.</p>
                        <p>Are you sure you want to override schedules?</p>
                      </>
                    )}
                    onConfirm={() => onStatusChange(locationId, 'On')}
                  >
                    <Radio.Button value="On">On</Radio.Button>
                  </Popconfirm>
                  <Popconfirm
                    okText="Confirm and Override"
                    placement="topLeft"
                    title={(
                      <>
                        <p>Changing this to Off will override the weekly/default schedule until it is set back to Auto.</p>
                        <p>Are you sure you want to override schedules?</p>
                      </>
                    )}
                    onConfirm={() => onStatusChange(locationId, 'Off')}
                  >
                    <Radio.Button value="Off">Off</Radio.Button>
                  </Popconfirm>
                </Radio.Group>
              <p style={{ marginTop: 16 }}>
              <Button onClick={() => onEditNotesClick(locationId)}>Edit Notes</Button>
              </p>
          </p>
          )}
        </Col>
        {notes.length > 0 && !mobile &&
<Col span={noteSpan}>
          <div className="camera-item" style={{ margin: 8 }}>
<p>{notes}</p>
            </div>
  </Col>}
        {cameraCards}
      </Row>
    </div>
  );
};

export default CameraCard;
