import React, { Component } from 'react';
import NProgress from 'nprogress';
import moment from 'moment-timezone';

import { getDispatch } from '../api';
import MobileDispatchPage from './MobileDispatchPage';

const initialState = {
  loading: false,
  loadCompleted: false,
  error: null,
  sites: [],
  lastRefresh: null,
  autoRefresh: true,
  timezoneType: 'Local',
  localTimezone: moment.tz.guess(),

  menuCollapsed: true,
  selectedSiteKey: '2',
};

class MobileDispatchController extends Component {
  constructor(props) {
    super(props);

    this.state = { ...initialState };

    this.onRefreshClick = this.onRefreshClick.bind(this);
    this.onSiteSelected = this.onSiteSelected.bind(this);
  }

  componentDidMount() {
    this.loadDispatch();

    window.setInterval(() => {
      this.loadDispatch();
    }, 5 * 60 * 1000); // Every 5 minutes
  }

  onRefreshClick() {
    this.loadDispatch();
  }

  onSiteSelected(x) {
    const value = x[1];
    const [siteId, productKey] = value.split('.');
    const site = this.state.sites.find((s) => s.siteId == siteId);
    const product = site.products.find((p) => p.product == productKey);

    this.setState({
      selectedSiteKey: value,
      selectedSite: site,
      selectedProduct: product,
    });
  }

  loadDispatch() {
    this.setState({
      loading: true,
      error: null,
    }, async () => {
      try {
        NProgress.start();
        const sites = await getDispatch(this.props.selectedCompany.name, true);

        this.setState({
          lastRefresh: new Date().toISOString(),
          sites,
          loading: false,
          loadCompleted: true,
        });
        NProgress.done();
      } catch (e) {
        this.setState({
          lastRefresh: null,
          sites: [],
          loading: false,
          error: e.body,
          loadCompleted: true,
        });
        NProgress.done();
      }
    });
  }

  render() {
    return (
      <MobileDispatchPage
        {...this.state}
        onRefreshClick={this.onRefreshClick}
        onSiteSelected={this.onSiteSelected}
        onLogoutClick={this.props.onLogoutClick}
      />
    );
  }
}

export default MobileDispatchController;
