import { Auth } from 'aws-amplify';

const api = async (path, options = {}) => {
  let response;
  try {
    const url = process.env.REACT_APP_API_URL + path;

    const user = await Auth.currentAuthenticatedUser({ bypassCache: false });
    const token = user.signInUserSession.idToken.jwtToken;

    const effOptions = {
      ...options,
      headers: {
        ...options.headers,
        Accept: 'application/json',
        Authorization: token,
      },
    };

    response = await fetch(url, effOptions);

    if (response.status === 200) {
      const json = await response.json();
      return json;
    }
    const error = new Error(`Client call to API (${url}) failed.`);
    error.url = url;
    error.status = response.status;

    if (response.headers.get('Content-Type').startsWith('application/json')) {
      error.body = await response.json();
    } else {
      error.body = {
        message: `The web application is unable to contact the server (${path}). ${response.statusText}`,
        uuid: `HTTP_${response.status}`,
      };
    }

    throw error;
  } catch (e) {
    if (!e.body) {
      e.body = {
        message: 'Unable to contact server',
        uuid: 'HTTP_FETCH',
      };
    }
    throw e;
  }
};

const deleteLocation = (locationId) => api(`/locations/${locationId}/`, {
  method: 'DELETE',
  mode: 'cors',
  headers: {
    'Content-Type': 'application/json',
  },
});

const getBinBank = (company, { binBankId }) => api(`/companies/${company}/binBanks/${binBankId}/`);

const getBinBankChart = async ({ company, binBankId, date }) => api(`/companies/${company}/binBanks/${binBankId}/chart?date=${date}`);

const getBinBankPreferences = (company) => api(`/companies/${company}/preferences/binBanks`);

const getCameraUrls = (company) => api(`/companies/${company}/cameras`);

const getCompanyHierarchy = (company) => api(`/companies/${company}/hierarchy`);

const listCompanyLocationAccess = (company) => api(`/companies/${company}/access/locations`);

const listCompanyBinBankAccess = (company) => api(`/companies/${company}/access/binBanks`);

const getCompanyLocations = (company) => api(`/companies/${company}/locations`);

const listCompanies = () => api(`/companies/`);

const getDispatch = (company, mobile) => api(`/companies/${company}/dispatch?mobile=${mobile}`);

const getLocation = (locationId) => api(`/locations/${locationId}`);

const getLocationPreferences = (company) => api(`/companies/${company}/preferences/locations`);

const getLocations = () => api('/locations');

const getLocationSchedule = (locationId, scheduleKey) => api(`/locations/${locationId}/schedules/${scheduleKey}`);

const getTruckerUsers = (company) => api(`/companies/${company}/users`);

const insertLocation = (body) => api('/locations/', {
  method: 'PUT',
  mode: 'cors',
  headers: {
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(body),
});

const listLocationTypes = () => api('/locationTypes');

const listTimezones = () => api('/timezones');

const resetPassword = (company, username) => api(`/companies/${company}/users/${username}/password`, {
  method: 'DELETE',
  mode: 'cors',
  headers: {
    'Content-Type': 'application/json',
  },
});

const setCompanyBinBankAccess = (companyCode, binBankId, access) => api(`/companies/${companyCode}/access/binBanks`, {
  method: 'PUT',
  mode: 'cors',
  headers: {
    'Content-Type': 'application/json',
  },
  body: JSON.stringify({ companyCode, binBankId, access }),
});

const setCompanyLocationAccess = (companyCode, locationId, access) => api(`/companies/${companyCode}/access/locations`, {
  method: 'PUT',
  mode: 'cors',
  headers: {
    'Content-Type': 'application/json',
  },
  body: JSON.stringify({ companyCode, locationId, access }),
});

const setBinBankPreferences = (company, binBankIds, body) => api(`/companies/${company}/preferences/binBanks`, {
  method: 'PUT',
  mode: 'cors',
  headers: {
    'Content-Type': 'application/json',
  },
  body: JSON.stringify({ binBankIds, color: body.color, visible: body.visible }),
});

const setLocationNotes = (locationId, body) => api(`/locations/${locationId}/notes`, {
  method: 'PUT',
  mode: 'cors',
  headers: {
    'Content-Type': 'application/json',
  },
  body: JSON.stringify({ notes: body.notes }),
});

const setLocationStatus = (locationId, body) => api(`/locations/${locationId}/status`, {
  method: 'PUT',
  mode: 'cors',
  headers: {
    'Content-Type': 'application/json',
  },
  body: JSON.stringify({ status: body.status }),
});

const setLocationPreferences = (company, body) => api(`/companies/${company}/preferences/locations`, {
  method: 'PUT',
  mode: 'cors',
  headers: {
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(body),
});

const updateDefaultSchedule = (locationId, body) => api(`/locations/${locationId}/schedules/default`, {
  method: 'PUT',
  mode: 'cors',
  headers: {
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(body),
});

const updateLocation = (locationId, body) => api(`/locations/${locationId}/`, {
  method: 'PUT',
  mode: 'cors',
  headers: {
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(body),
});

const updateWeeklySchedule = (locationId, weeklyScheduleDate, body) => api(`/locations/${locationId}/schedules/${weeklyScheduleDate}`, {
  method: 'PUT',
  mode: 'cors',
  headers: {
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(body),
});

export {
  deleteLocation,
  getBinBank,
  getBinBankChart,
  getCameraUrls,
  listCompanies,
  getCompanyHierarchy,
  getCompanyLocations,
  listCompanyLocationAccess,
  listCompanyBinBankAccess,
  getLocation,
  getLocations,
  getLocationSchedule,
  insertLocation,
  listLocationTypes,
  listTimezones,
  getBinBankPreferences,
  getLocationPreferences,
  getDispatch,
  getTruckerUsers,
  resetPassword,
  setBinBankPreferences,
  setCompanyBinBankAccess,
  setCompanyLocationAccess,
  setLocationPreferences,
  setLocationNotes,
  setLocationStatus,
  updateDefaultSchedule,
  updateLocation,
  updateWeeklySchedule,
};
