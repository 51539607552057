import {
  Card, Row, Col, Statistic, Alert, Spin,
} from 'antd';
import moment from 'moment-timezone';
import React from 'react';

const ChartStats = (params) => {
  const {
    liveView,
    selectedBinBank,
    stats,
    statsLoaded,
    statsLoading,
    timezone,
  } = params;

  let content;

  if (!selectedBinBank) {
    content = <Alert showIcon type="info" description="Choose a Bin Bank to display current levels." />;
  } else if (!liveView) {
    content = <Alert showIcon type="info" description="Current levels are only shown in Live mode." />;
  } else if (!statsLoaded && statsLoading) {
    content = <Spin />;
  } else {
    const currentM3 = stats && stats.currentM3 !== null ? Math.round(stats.currentM3) : 'N/A';
    const capacityM3 = stats && stats.capacityM3 !== null ? `/ ${Math.round(stats.capacityM3)}` : '';
    const currentODT = stats && stats.currentODT !== null ? Math.round(stats.currentODT) : 'N/A';
    const capacityODT = stats && stats.capacityODT !== null ? `/ ${Math.round(stats.capacityODT)}` : '';
    const percentM3 = stats && stats.percentM3 !== null ? Math.round(stats.percentM3 * 100.0) : 'N/A';
    const estFull = stats && stats.estimatedTimeFullUtc ? moment(stats.estimatedTimeFullUtc).tz(timezone).format('hh:mm A z') : 'N/A';

    content = (
      <Row className="chart-stats">
        <Col xs={12} sm={6}>
          <Statistic title="M3" value={currentM3} suffix={capacityM3} />
        </Col>
        <Col xs={12} sm={6}>
          <Statistic title="ODT" value={currentODT} suffix={capacityODT} />
        </Col>
        <Col xs={12} sm={6}>
          <Statistic title="Percent" value={percentM3} suffix={percentM3 !== 'N/A' ? '%' : ''} />
        </Col>
        <Col xs={12} sm={6}>
          <Statistic title="Est Time Full" value={estFull} />
        </Col>
      </Row>
    );
  }

  return (
    <Card className="chart-stats-card">
      {content}
    </Card>
  );
};

export default ChartStats;
