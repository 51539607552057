import React, { Component } from 'react';

import ErrorPage from '../Common/ErrorPage';
import CompanyPage from './CompanyPage';

import {
  listCompanies,
  listCompanyBinBankAccess,
  listCompanyLocationAccess,
  setCompanyBinBankAccess,
  setCompanyLocationAccess,
} from '../api';

const initialState = {
  loading: false,
  companies: [],
  locationAccess: [],
  binBankAccess: [],
  error: null,
  tabKey: 'locations',
};

class CompanyController extends Component {
  constructor(props) {
    super(props);

    this.state = { ...initialState };

    this.onCompanyChange = this.onCompanyChange.bind(this);
    this.onLocationAccessCheck = this.onLocationAccessCheck.bind(this);
    this.onBinBankAccessCheck = this.onBinBankAccessCheck.bind(this);
    this.onTabChange = this.onTabChange.bind(this);
  }

  async componentDidMount() {
    await this.loadCompanies();
  }

  loadCompanies() {
    this.setState({
      loading: true,
      error: null,
    }, async () => {
      listCompanies()
        .then((companies) => {
          this.setState({
            loading: false,
            companies,
          });
        }).catch((error) => {
          this.setState({
            loading: false,
            companies: [],
            error: error.body,
          });
        });
    });
  }

  loadCompanyBinBankAccess(companyId) {
    this.setState({
      loading: true,
      error: null,
    }, async () => {
      listCompanyBinBankAccess(companyId)
        .then((binBankAccess) => {
          this.setState({
            loading: false,
            binBankAccess,
            error: null,
          });
        }).catch((error) => {
          this.setState({
            loading: false,
            binBankAccess: [],
            error: error.body,
          });
        });
    });
  }

  loadCompanyLocationAccess(companyId) {
    this.setState({
      loading: true,
      error: null,
    }, async () => {
      listCompanyLocationAccess(companyId)
        .then((locationAccess) => {
          this.setState({
            loading: false,
            locationAccess,
            error: null,
          });
        }).catch((error) => {
          this.setState({
            loading: false,
            locationAccess: [],
            error: error.body,
          });
        });
    });
  }

  loadCompany(companyId) {
    this.setState({
      companyId,
      loading: true,
      error: null,
    }, async () => {
      await this.loadCompanyBinBankAccess(companyId);
      await this.loadCompanyLocationAccess(companyId);
    });
  }

  onCompanyChange(companyId) {
    this.loadCompany(companyId);
  }

  onLocationAccessCheck(companyId, locationId, checked) {
    this.setState({
      error: null,
    }, () => {
      // Default to white if not specified
      setCompanyLocationAccess(companyId, locationId, checked)
        .then(() => {
          const la = this.state.locationAccess.slice(0);
          la.find(l => l.locationId === locationId).access = checked;
          this.setState({
            locationAccess: la
          })
        })
        .catch((error) => {
          this.setState({
            error: error.body,
          });
        });
    });
  }

  onBinBankAccessCheck(companyId, binBankId, checked) {
    this.setState({
      error: null,
    }, () => {
      // Default to white if not specified
      setCompanyBinBankAccess(companyId, binBankId, checked)
        .then(() => {
          const la = this.state.binBankAccess.slice(0);
          la.find(l => l.binBankId === binBankId).access = checked;
          this.setState({
            binBankId: la
          })
        })
        .catch((error) => {
          this.setState({
            error: error.body,
          });
        });
    });
  }

  onTabChange(tabKey) {
    this.setState({ tabKey });
  }

  render() {
    return (
      <>
        <ErrorPage error={this.state.error}>
          <CompanyPage
            {...this.state}
            onCompanyChange={this.onCompanyChange}
            onLocationAccessCheck={this.onLocationAccessCheck}
            onBinBankAccessCheck={this.onBinBankAccessCheck}
            onTabChange={this.onTabChange}
          />
        </ErrorPage>
      </>
    );


  }
}

export default CompanyController;
