import React from 'react';
import {
  Form, Row, Col, Button, Card, Input, Select, Popconfirm, Alert,
} from 'antd';
import { DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import CameraTable from './CameraTable';
import DependencyTable from './DependencyTable';

const { Option } = Select;

const LocationSetupPage = (props) => {
  const {
    cameraErrorMessage,
    locationErrorMessage,
    location,
    locationTypes,
    onEditLocation,
    onSetupSave,
    onSetupDelete,
    isDumper,
    locationId,
    timezones,
  } = props;

  if (!location) {
    return 'No Location Selected/Loaded';
  }

  const timezoneOptions = timezones.map((tz) => <Option key={tz.timezoneId} value={tz.timezoneId}>{tz.timezone}</Option>);
  const locationTypeOptions = locationTypes.map((lt) => <Option key={lt.locationTypeId} value={lt.locationTypeId}>{lt.locationTypeName}</Option>);

  return (
    <div>
      <Form
        labelCol={{
          xs: { span: 8 }, sm: { span: 6 }, xl: { span: 2 },
        }}
        wrapperCol={{
          xs: { span: 16 }, sm: { span: 18 }, xl: { span: 22 },
        }}
        fields={[{ name: ['locationName'], value: location.locationName }, { name: ['locationTypeId'], value: location.locationTypeId }, { name: ['timezoneId'], value: location.timezoneId }]}
        onFinish={onSetupSave}
      >

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Card size="small" title="General" style={{ marginBottom: '16px' }}>
              {locationErrorMessage && <Alert style={{ marginBottom: 16 }} message={locationErrorMessage} type="error" showIcon />}
              <Form.Item
                label="Name"
                name="locationName"
                rules={[{ required: true, message: 'Location name is required' }]}
              >
                <Input style={{ width: 240 }} value={location.locationName} onChange={(event) => onEditLocation(event.target.value, 'locationName')} />
              </Form.Item>
              <Form.Item
                label="Type"
                name="locationTypeId"
                rules={[{ required: true, message: 'Location type is required' }]}
              >
                <Select disabled={locationId !== null} value={location.locationTypeId} style={{ width: 240 }} onChange={(value) => onEditLocation(value, 'locationTypeId')}>
                  {locationTypeOptions}
                </Select>
              </Form.Item>
              <Form.Item
                label="Timezone"
                name="timezoneId"
                rules={[{ required: true, message: 'Location timezone is required' }]}
              >
                <Select value={location.timezoneId} style={{ width: 240 }} onChange={(value) => onEditLocation(value, 'timezoneId')}>
                  {timezoneOptions}
                </Select>
              </Form.Item>
            </Card>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Card size="small" title="Cameras" style={{ marginBottom: '16px' }}>
              {cameraErrorMessage && <Alert style={{ marginBottom: 16 }} message={cameraErrorMessage} type="error" showIcon />}
              <CameraTable {...props} />

            </Card>
          </Col>
          <Col span={12}>
            <Card size="small" title="Dependencies" style={{ marginBottom: '16px' }}>
              <DependencyTable disabled={!isDumper} {...props} />
            </Card>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Button disabled={cameraErrorMessage || locationErrorMessage} style={{ marginRight: 16 }} type="primary" htmlType="submit" icon={<SaveOutlined />}>Save Location</Button>
            <Popconfirm
              okText="Confirm and Delete"
              placement="topLeft"
              title={(
                <>
                  <p>Deleting a location will delete all of its associated cameras, dependencies and schedules.</p>
                  <p>Are you sure you want to delete?</p>
                </>
)}
              onConfirm={onSetupDelete}
            >
              <Button disabled={!locationId} icon={<DeleteOutlined />}>Delete Location</Button>
            </Popconfirm>

          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default LocationSetupPage;
