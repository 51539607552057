import React from 'react';
import {
  Row, Col, Card, Radio, DatePicker, Button, Typography, Popconfirm, Alert,
} from 'antd';
import { CopyOutlined, PlusOutlined } from '@ant-design/icons';
import ScheduleTable from './ScheduleTable';
import WeeklyScheduleSection from './WeeklyScheduleSection';

const LocationSchedulePage = (props) => {
  const {
    location,
    onStatusChange,
    onWeeklyScheduleChange,
    weeklyScheduleDate,
    onAddSchedule,
    onCopySchedule,
    onRevertSchedule,
    defaultSchedules,
    defaultSchedulesDirty,
    locationId,
  } = props;

  let locationForm;

  if (location) {
    const { status } = location;
    const weeklyEmptyText = `No specific scheduled downtime for week of ${weeklyScheduleDate.format('YYYY-MM-DD')}. Default schedule will be used.`;
    return (
      <div>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Card>
              <Row>
                <Col span={9}>
                  <span style={{ marginRight: 8 }}>Status:</span>
                  <Radio.Group buttonStyle="solid" value={status}>
                    <Popconfirm
                      okText="Confirm and Override"
                      placement="topLeft"
                      title={(
                        <>
                          <p>Changing this to to Auto will enable the weekly/default schedule.</p>
                          <p>Are you sure you want to enable schedules?</p>
                        </>
                      )}
                      onConfirm={() => onStatusChange(locationId, 'Auto')}
                    >
                      <Radio.Button value="Auto">Auto</Radio.Button>
                    </Popconfirm>
                    <Popconfirm
                      okText="Confirm and Override"
                      placement="topLeft"
                      title={(
                        <>
                          <p>Changing this to On will override the weekly/default schedule until it is set back to Auto.</p>
                          <p>Are you sure you want to override schedules?</p>
                        </>
                      )}
                      onConfirm={() => onStatusChange(locationId, 'On')}
                    >
                      <Radio.Button value="On">On</Radio.Button>
                    </Popconfirm>
                    <Popconfirm
                      okText="Confirm and Override"
                      placement="topLeft"
                      title={(
                        <>
                          <p>Changing this to Off will override the weekly/default schedule until it is set back to Auto.</p>
                          <p>Are you sure you want to override schedules?</p>
                        </>
                      )}
                      onConfirm={() => onStatusChange(locationId, 'Off')}
                    >
                      <Radio.Button value="Off">Off</Radio.Button>
                    </Popconfirm>
                  </Radio.Group>

                </Col>
                <Col span={15}>
                  {(status === 'Auto' && (
                  <p>
                      Status is currently set to {status}. Schedules are in effect.
                  </p>
                  )) || <p>
                      Status is currently set to {status}. Schedules are ignored.
                  </p>}
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={12}>
            <Alert
              message="Date Formats"
              description="Note that all dates are to be specified in the timezone of the location (not the user's timezone, which may be different)."
              type="info"
              showIcon
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <ScheduleTable
              onRevert={() => onRevertSchedule('weeklySchedules')}
              extra={(
                <>
                  <Button style={{ marginRight: 8 }} icon={<PlusOutlined />} onClick={() => onAddSchedule('weeklySchedules')}> Add Row </Button>
                  <Button disabled={defaultSchedulesDirty || defaultSchedules.length === 0} icon={<CopyOutlined />} onClick={() => onCopySchedule()}> Copy from Default </Button>
                </>
)}
              title={(
                <div>
                  Weekly Schedule:
                  <DatePicker allowClear={false} value={weeklyScheduleDate} onChange={onWeeklyScheduleChange} format="YYYY-MM-DD" picker="week" />
                </div>
)}
              scheduleType="weeklySchedules"
              dirty={props.weeklySchedulesDirty}
              dataSource={props.weeklySchedules}
              {...props}
              emptyText={weeklyEmptyText}
              tableLoading={props.weeklySchedulesLoading}
            />
          </Col>
          <Col span={12}>
            <ScheduleTable
              onRevert={() => onRevertSchedule('defaultSchedules')}
              extra={<Button icon={<PlusOutlined />} onClick={() => onAddSchedule('defaultSchedules')}> Add Row </Button>}
              title="Default Schedule"
              scheduleType="defaultSchedules"
              dirty={props.defaultSchedulesDirty}
              dataSource={props.defaultSchedules}
              {...props}
              emptyText="No default scheduled downtime"
              tableLoading={props.defaultSchedulesLoading}
            />
          </Col>
        </Row>
        {/* <Row gutter={[16, 16]}>
          <Col span={12}>
            <WeeklyScheduleSection locationId={locationId} />
            <ScheduleTable extra={<><Button style={{ marginRight: 8 }} icon={<PlusOutlined />} onClick={() => onAddSchedule('weeklySchedules')}> Add Row </Button><Button icon={<CopyOutlined />} onClick={() => onCopySchedule()}> Copy from Default </Button></>} title={<div>Weekly Schedule: <DatePicker allowClear={false} value={weeklyScheduleDate} onChange={onWeeklyScheduleChange} format="YYYY-MM-DD" picker="week" /></div>} scheduleType="weeklySchedules" dataSource={props.weeklySchedules} {...props} emptyText={weeklyEmptyText} />
          </Col>
        </Row> */}
      </div>
    );
  }

  return (
    <div className="location-page">
      {locationForm}
      {/* {props.error && <Alert type="error" showIcon message={props.error} style={{ marginBottom: '16px' }} /> }
      <div className="preferences-table-wrapper">
        <SizeMe monitorHeight>
          {({ size }) => <LocationPreferencesTable {...props} size={200} />}
        </SizeMe>
      </div> */}
    </div>
  );
};

export default LocationSchedulePage;
