import { Layout } from 'antd';
import React from 'react';
import { Switch, Route } from 'react-router-dom';

import NotFound from '../Common/NotFound';
import NotAuthorized from '../Common/NotAuthorized';
import CamerasController from '../Cameras/CamerasController';
import MobileDispatchController from '../MobileDispatch/MobileDispatchController';
import MobileMenu from './MobileMenu';

const { Header, Content, Footer } = Layout;

const MobileController = (props) => (
  <Layout className="trucker-layout">
    <Header>
      <MobileMenu menuCollapsed={false} selectedSiteKey={props.selectedSiteKey} onSiteSelected={props.onSiteSelected} sites={props.sites} onLogoutClick={props.onLogoutClick} />
    </Header>
    <Content>
      <Switch>
        <Route exact path="/cameras" render={() => <CamerasController {...props} mobile />} />
        <Route exact path="/dispatch" render={() => <MobileDispatchController {...props} />} />
        <Route exact path="/" render={() => <MobileDispatchController {...props} />} />
        <Route component={NotFound} />
      </Switch>
    </Content>
    <Footer>
      <div className="footer">
        <div className="copyright">
          {`${process.env.REACT_APP_LONG_NAME} `}
          {`(${process.env.REACT_APP_SHORT_NAME}) `}
          ©2019 Canfor.
        </div>
        <div className="implemented">
          {'Implemented by '}
          <a href="https://www.ascentech.ca" target="_blank" rel="noopener noreferrer">AscenTech</a>
        </div>
        <div className="build">
          {'Build: '}
          {process.env.REACT_APP_BUILD_VERSION}
        </div>
      </div>
    </Footer>
  </Layout>
);

export default MobileController;
