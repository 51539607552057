import { Alert } from 'antd';
import React from 'react';

const ErrorAlert = ({ error, style, className }) => {
  if (!error) {
    return null;
  }

  let message = '';
  let description = '';

  console.log('Error', error);
  const errorType = typeof error;

  if (typeof error === 'string') {
    message = error;
  } else if (typeof error === 'object') {
    // This is an API error
    if (error.body) {
      description = (
        <>
          <span>{error.body.message}</span>
          <span style={{ float: 'right', fontFamily: 'monospace', fontSize: '11px' }}>{`Error Code: ${error.body.uuid}`}</span>
        </>
      );
    } else {
      description = (
        <>
          <span>{error.message}</span>
          <span style={{ float: 'right', fontFamily: 'monospace', fontSize: '11px' }}>Error Code: CLIENT_SIDE</span>
        </>
      );
    }
  } else {
    message = 'Unknown error occured.';
  }

  return (
    <Alert
      className={className}
      showIcon
      style={style}
      type="error"
      // message={<><span>hi</span><span style={{ float: 'right', fontFamily: 'monospace', fontSize: '11px' }}>{description}</span></>}
      description={description}
    />
  );
};

export default ErrorAlert;
