import _ from 'lodash';
import React, { Component } from 'react';
import { Button } from 'antd';
import ButtonGroup from 'antd/lib/button/button-group';

class RadioButtonGroup extends Component {
  constructor(props) {
    super(props);

    // this.onChange = props.onChange;
    this.onClick = this.onClick.bind(this);
  }

  onClick(e) {
    const { selectedOptions, onChange } = this.props;

    const clickedOption = e.target.value;
    let newSelectedOptions;

    if (!_.isArray(selectedOptions)) {
      newSelectedOptions = [clickedOption];
    } else {
      // Determine if the clicked option is currently filtered or not.
      const alreadySelected = _.includes(selectedOptions, clickedOption);
      if (alreadySelected) {
        const filterIndex = selectedOptions.indexOf(clickedOption);

        // Remove the clicked product
        newSelectedOptions = [
          ...selectedOptions.slice(0, filterIndex),
          ...selectedOptions.slice(filterIndex + 1),
        ];

        // By default the button will be left focused, which causes an outline around the button.
        // This makes it look like it is still the selected button. So, if we are "unclicking" the
        // button then remove the focus to remove the outline.
        e.target.blur();
      } else {
        newSelectedOptions = [clickedOption];
      }
    }

    onChange(newSelectedOptions);
  }

  render() {
    const { options, selectedOptions, style } = this.props;
    const buttons = _.map(options, (o) => <Button key={o} value={o} type={_.includes(selectedOptions, o) ? 'primary' : ''} onClick={this.onClick}>{o}</Button>);
    return (
      <ButtonGroup style={style}>
        {buttons}
      </ButtonGroup>
    );
  }
}

export default RadioButtonGroup;
