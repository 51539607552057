import _ from 'lodash';
import React, { Component } from 'react';
import moment from 'moment';

import {
  getLocationSchedule, updateWeeklySchedule, updateDefaultSchedule, insertLocation, deleteLocation, updateLocation, listLocationTypes, listTimezones, getLocations, getLocation, setLocationStatus,
} from '../api';
import LocationPage from './LocationPage';
// import LocationPreferencesModal from './LocationPreferencesModal';
import ErrorPage from '../Common/ErrorPage';
import CameraTable from './CameraTable';

const initialState = {
  // True if the products or preferences are currently loading.
  loading: false,
  // An array of location/preferences data to be displayed as rows in a table.
  locations: [],
  timezones: [],
  // The location that has been selected
  locationId: null,
  error: null,
  tabKey: 'setup',
  weeklyScheduleDate: moment().startOf('week'),
  cameraErrorMessage: null,
  locationErrorMessage: null,
  defaultSchedulesErrorMessage: null,
  weeklySchedulesErrorMessage: null,
  weeklySchedules: [],
  defaultSchedules: [],
};

const hasDuplicates = (array) => (new Set(array)).size !== array.length;

const checkScheduledDowntimeArray = (array) => {
  if (array.length === 0) {
    return;
  }

  const ranges = array.map((a) => {
    if (!moment(a.startTime, 'HH:mm', true).isValid() || !moment(a.endTime, 'HH:mm', true).isValid()) {
      throw new Error('Incomplete Start/End times');
    }

    const start = moment(`${moment().startOf('week').add(a.startDay, 'days').format('YYYY-MM-DD')} ${a.startTime}`);
    const end = moment(`${moment().startOf('week').add(a.endDay, 'days').format('YYYY-MM-DD')} ${a.endTime}`);

    return {
      start,
      end,
    };
  });

  for (let i = 0; i < ranges.length; i += 1) {
    const { start, end } = ranges[i];

    if (start.isSameOrAfter(end)) {
      throw new Error('Start Day/Time must be before End Day/Time.');
    }

    for (let j = 0; j < array.length; j += 1) {
      if (i !== j) {
        const { start: start2, end: end2 } = ranges[j];

        if (start.isBetween(start2, end2) || end.isBetween(start2, end2)) {
          throw new Error('Scheduled downtime cannot overlap');
        }
      }
    }
  }
};

class LocationController extends Component {
  constructor(props) {
    super(props);

    this.state = { ...initialState };

    this.onLocationChange = this.onLocationChange.bind(this);
    this.onTabChange = this.onTabChange.bind(this);
    this.onSetupSave = this.onSetupSave.bind(this);
    this.onSetupDelete = this.onSetupDelete.bind(this);

    this.onCreateLocation = this.onCreateLocation.bind(this);
    this.onEditLocation = this.onEditLocation.bind(this);
    this.checkLocation = this.checkLocation.bind(this);

    this.onStatusChange = this.onStatusChange.bind(this);
    this.setStatus = this.setStatus.bind(this);

    this.onAddCamera = this.onAddCamera.bind(this);
    this.onEditCamera = this.onEditCamera.bind(this);
    this.onDeleteCamera = this.onDeleteCamera.bind(this);
    this.checkCamera = this.checkCamera.bind(this);

    this.onAddDependency = this.onAddDependency.bind(this);
    this.onEditDependency = this.onEditDependency.bind(this);
    this.onDeleteDependency = this.onDeleteDependency.bind(this);

    this.onAddSchedule = this.onAddSchedule.bind(this);
    this.onEditSchedule = this.onEditSchedule.bind(this);
    this.onDeleteSchedule = this.onDeleteSchedule.bind(this);
    this.onSaveSchedule = this.onSaveSchedule.bind(this);
    this.onWeeklyScheduleChange = this.onWeeklyScheduleChange.bind(this);
    this.onCopySchedule = this.onCopySchedule.bind(this);
    this.checkScheduledDowntime = this.checkScheduledDowntime.bind(this);
    this.onRevertSchedule = this.onRevertSchedule.bind(this);
  }

  async componentDidMount() {
    await this.loadLocations();
    await this.loadLocationTypes();
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.selectedCompany.name !== prevProps.selectedCompany.name) {
      this.setState({ ...({ ...initialState }) }, () => {
        this.loadLocations();
      });
    }
  }

  onTabChange(tabKey) {
    this.setState({ tabKey });
  }

  onCopySchedule() {
    this.setState({
      weeklySchedules: [...this.state.defaultSchedules].map((s, index) => ({ ...s, scheduleId: `new_${index}` })),
    }, () => this.checkScheduledDowntime('weeklySchedules'));
  }

  async onRevertSchedule(scheduleType) {
    if (scheduleType === 'weeklySchedules') {
      await this.loadLocationWeeklySchedule(this.state.locationId);
    } else {
      await this.loadLocationDefaultSchedule(this.state.locationId);
    }
  }

  onLocationChange(locationId) {
    this.loadLocation(locationId);
  }

  onStatusChange(_locationId, status) {
    console.log('Trying to set status to this', status);
    this.setStatus(status);
  }

  checkLocation() {
    const { locationId, locationName } = this.state.location;
    let locationErrorMessage = '';

    const duplicationLocation = this.state.locations.find((l) => locationName === l.locationName && locationId !== l.locationId);

    if (duplicationLocation) {
      locationErrorMessage = 'Location names must be unique.';
    }

    this.setState({ locationErrorMessage });
  }

  onEditLocation(value, field) {
    if (field === 'locationTypeId' && this.state.locationTypes.find((lt) => lt.locationTypeId === value).name !== 'Dumper') {
      this.setState({
        location: { ...this.state.location, [field]: value, dependencies: [] },
      }, () => this.checkLocation());
    } else {
      this.setState({
        location: { ...this.state.location, [field]: value },
      }, () => this.checkLocation());
    }
  }

  onWeeklyScheduleChange(value) {
    this.setState({
      weeklyScheduleDate: value.startOf('week'),
    }, () => {
      this.loadLocationWeeklySchedule(this.state.locationId);
    });
  }

  onAddSchedule(scheduleType) {
    const schedules = this.state[scheduleType];

    this.setState({
      [scheduleType]: [...schedules, {
        scheduleId: `new_${schedules.length + 1}`,
        startDay: null,
        startTime: null,
        endDay: null,
        endTime: null,
      }],
    }, () => this.checkScheduledDowntime(scheduleType));
  }

  onDeleteSchedule(scheduleType, scheduleId) {
    const schedules = this.state[scheduleType];

    this.setState({
      [scheduleType]: [...schedules].filter((c) => c.scheduleId !== scheduleId),
    }, () => this.checkScheduledDowntime(scheduleType));
  }

  onEditSchedule(scheduleType, scheduleId, event, field) {
    const value = event.target ? event.target.value : event;

    const newSchedule = [...this.state[scheduleType]];
    const index = newSchedule.findIndex((item) => scheduleId === item.scheduleId);
    const item = newSchedule[index];
    const newItem = { ...item, [field]: value };
    newSchedule.splice(index, 1, { ...item, ...newItem });

    this.setState({
      [scheduleType]: newSchedule,
    }, () => this.checkScheduledDowntime(scheduleType));
  }

  checkCamera() {
    let cameraErrorMessage = '';

    if (hasDuplicates(this.state.location.cameras.map((c) => c.camera)) || hasDuplicates(this.state.location.cameras.map((c) => c.filename))) {
      cameraErrorMessage = 'Camera names and filenames must be unique.';
    }

    this.setState({
      cameraErrorMessage,
    });
  }

  onAddCamera() {
    const { location } = this.state;
    const newCameras = [...location.cameras, {
      cameraId: `new_${location.cameras.length + 1}`,
      camera: '',
      filename: '',
    }];
    const newLocation = { ...location, cameras: newCameras };

    this.setState({ location: newLocation }, () => {
    });
  }

  onDeleteCamera(cameraId) {
    const cameras = [...this.state.location.cameras].filter((c) => c.cameraId !== cameraId);
    const newLocation = { ...this.state.location, cameras };

    this.setState({
      location: newLocation,
    }, () => {
      this.checkCamera();
    });
  }

  onEditCamera(cameraId, event, field) {
    const { value } = event.target;

    const newCameras = [...this.state.location.cameras];
    const index = newCameras.findIndex((item) => cameraId === item.cameraId);
    const item = newCameras[index];
    const newItem = { ...item, [field]: value };

    newCameras.splice(index, 1, { ...item, ...newItem });
    this.setState({
      location: { ...this.state.location, cameras: newCameras },
    }, () => {
      this.checkCamera();
    });
  }

  onAddDependency() {
    const { location } = this.state;
    const newDependencies = [...location.dependencies, {
      dependencyId: `new_${location.dependencies.length + 1}`,
      locationId: null,
    }];
    const newLocation = { ...location, dependencies: newDependencies };

    this.setState({ location: newLocation });
  }

  onDeleteDependency(dependencyId) {
    const dependencies = [...this.state.location.dependencies].filter((c) => c.dependencyId !== dependencyId);
    const newLocation = { ...this.state.location, dependencies };

    this.setState({
      location: newLocation,
    });
  }

  onEditDependency(dependencyId, event, field) {
    const value = event;

    const newDependencies = [...this.state.location.dependencies];
    const index = newDependencies.findIndex((item) => dependencyId === item.dependencyId);
    const item = newDependencies[index];
    const newItem = { ...item, [field]: value };
    newDependencies.splice(index, 1, { ...item, ...newItem });
    this.setState({
      location: { ...this.state.location, dependencies: newDependencies },
    });
  }

  onCreateLocation() {
    this.setState({
      tabKey: 'setup',
      locationId: null,
      location: {
        locationName: '',
        locationTypeId: null,
        cameras: [],
        dependencies: [],
      },
    });
  }

  onSetupSave(values) {
    this.setState({
      saving: true,
      error: null,
    }, () => {
      const saveLocation = { ...this.state.location };
      saveLocation.cameras = saveLocation.cameras.map((c) => {
        if (typeof c.cameraId === 'string') {
          c.cameraId = null;
        }
        return c;
      });
      saveLocation.dependencies = saveLocation.dependencies.map((c) => {
        if (typeof c.dependencyId === 'string') {
          c.dependencyId = null;
        }
        return c;
      });

      if (this.state.locationId) {
        // Default to white if not specified

        updateLocation(this.state.locationId, saveLocation)
          .then(() => {
            this.setState({
              saving: false,
            }, () => {
              this.loadLocation(this.state.locationId);
              this.loadLocations();
            });
          })
          .catch((error) => {
            this.setState({
              error: error.body,
              saving: false,
            });
          });
      } else {
        insertLocation(saveLocation)
          .then((res) => {
            this.setState({
              saving: false,
              locationId: res.locationId,
            }, () => {
              this.loadLocation(this.state.locationId);
              this.loadLocations();
            });
          })
          .catch((error) => {
            this.setState({
              error: error.body,
              saving: false,
            });
          });
      }
    });
  }

  checkScheduledDowntime(scheduleType) {
    let errorMessage = null;

    try {
      checkScheduledDowntimeArray(this.state[scheduleType]);
    } catch (err) {
      errorMessage = err.message;
    }

    const dirty = JSON.stringify(this.state[scheduleType]) !== JSON.stringify(this.state[`${scheduleType}Clean`]);

    this.setState({
      [`${scheduleType}ErrorMessage`]: errorMessage,
      [`${scheduleType}Dirty`]: dirty,
    });
  }

  onSaveSchedule(scheduleType) {
    try {
      this.checkScheduledDowntime(this.state[scheduleType]);
    } catch (err) {
      this.setState({ error: err });
      return;
    }

    this.setState({
      error: null,
    }, () => {
      const schedules = [...this.state[scheduleType]].map((s) => {
        const newSchedule = { ...s };
        if (typeof s.scheduleId === 'string') {
          newSchedule.scheduleId = null;
        }
        return newSchedule;
      });

      if (scheduleType === 'defaultSchedules') {
        updateDefaultSchedule(this.state.locationId, { schedules })
          .then(() => {
            // this.setState({ saving: false });
            this.setState({
              // saving: false,
            }, () => {
              this.loadLocationDefaultSchedule(this.state.locationId);
            });
          })
          .catch((error) => {
            this.setState({
              error: error.body,
              // saving: false,
            });
          });
      } else {
        updateWeeklySchedule(this.state.locationId, this.state.weeklyScheduleDate.format('YYYY-MM-DD'), { schedules })
          .then(() => {
            this.setState({
              // saving: false,
            }, () => {
              this.loadLocationWeeklySchedule(this.state.locationId);
              // this.loadLocation(this.state.locationId);
            });
          })
          .catch((error) => {
            this.setState({
              error: error.body,
              // saving: false,
            });
          });
      }
    });
  }

  onSetupDelete() {
    this.setState({
      saving: true,
      error: null,
    }, () => {
      deleteLocation(this.state.locationId)
        .then(() => {
          this.setState({
            saving: false,
            locationId: null,
            location: null,
          }, () => {
            this.loadLocations();
          });
        })
        .catch((error) => {
          this.setState({
            error: error.body,
            saving: false,
          });
        });
    });
  }

  setStatus(status) {
    this.setState({
      savingOverride: true,
      error: null,
    }, () => {
      // Default to white if not specified
        setLocationStatus(this.state.locationId, { status })
        .then(() => {
          this.setState({
            savingOverride: false,
          }, () => {
            this.loadLocation(this.state.locationId);
          });
        })
        .catch((error) => {
          this.setState({
            error: error.body,
            savingOverride: false,
          });
        });
    });
  }

  loadLocations() {
    this.setState({
      loading: true,
      error: null,
    }, async () => {
      getLocations()
        .then((locations) => {
          this.setState({
            loading: false,
            locations,
          });
        }).catch((error) => {
          this.setState({
            loading: false,
            locations: [],
            error: error.body,
          });
        });
    });
  }

  loadLocationTypes() {
    this.setState({
      loading: true,
      error: null,
    }, async () => {
      try {
        const locationTypes = await listLocationTypes();
        const timezones = await listTimezones();

        this.setState({
          loading: false,
          locationTypes,
          timezones,
          error: null,
        });
      } catch (error) {
        this.setState({
          loading: false,
          locationTypes: [],
          timezones: [],
          error: error.body,
        });
      }
    });
  }

  loadLocationDefaultSchedule(locationId) {
    this.setState({
      defaultSchedulesLoading: true,
      error: null,
      defaultSchedulesErrorMessage: null,
    }, async () => {
      getLocationSchedule(locationId, 'default')
        .then((schedule) => {
          const s = schedule.map((s) => ({ ...s }));
          this.setState({
            defaultSchedulesLoading: false,
            defaultSchedules: schedule.map((s) => ({ ...s })),
            defaultSchedulesClean: schedule.map((s) => ({ ...s })),
            defaultSchedulesDirty: false,
          });
        }).catch((error) => {
          this.setState({
            defaultSchedulesLoading: false,
            defaultSchedules: [],
            defaultSchedulesClean: [],
            defaultSchedulesDirty: false,
            error: error.body,
          });
        });
    });
  }

  loadLocationWeeklySchedule(locationId) {
    if (!this.state.weeklyScheduleDate) {
      this.setState({ weeklySchedules: [] });
      return;
    }

    this.setState({
      weeklySchedulesLoading: true,
      error: null,
      weeklySchedulesErrorMessage: null,
    }, async () => {
      getLocationSchedule(locationId, this.state.weeklyScheduleDate.format('YYYY-MM-DD'))
        .then((schedule) => {
          this.setState({
            weeklySchedulesLoading: false,
            weeklySchedulesClean: schedule.map((s) => ({ ...s })),
            weeklySchedules: schedule.map((s) => ({ ...s })),
            weeklySchedulesDirty: false,
          });
        }).catch((error) => {
          this.setState({
            weeklySchedulesLoading: false,
            weeklySchedules: [],
            weeklySchedulesClean: [],
            weeklySchedulesDirty: false,
            error: error.body,
          });
        });
    });
  }

  loadLocation(locationId) {
    this.setState({
      locationId,
      loading: true,
      error: null,
    }, async () => {
      getLocation(locationId)
        .then((location) => {
          this.setState({
            loading: false,
            location,
          });
        }).catch((error) => {
          this.setState({
            loading: false,
            location: null,
            error: error.body,
          });
        });

      this.loadLocationDefaultSchedule(locationId);
      this.loadLocationWeeklySchedule(locationId);
    });
  }

  render() {
    return (
      <>
        <ErrorPage error={this.state.error}>
          <LocationPage
            {...this.state}
            onSetupSave={this.onSetupSave}
            onLocationChange={this.onLocationChange}
            onAddSchedule={this.onAddSchedule}
            onEditSchedule={this.onEditSchedule}
            onDeleteSchedule={this.onDeleteSchedule}
            onSaveSchedule={this.onSaveSchedule}
            onAddCamera={this.onAddCamera}
            onEditCamera={this.onEditCamera}
            onDeleteCamera={this.onDeleteCamera}
            onAddDependency={this.onAddDependency}
            onEditDependency={this.onEditDependency}
            onDeleteDependency={this.onDeleteDependency}
            onEditLocation={this.onEditLocation}
            onSetupDelete={this.onSetupDelete}
            onCreateLocation={this.onCreateLocation}
            onStatusChange={this.onStatusChange}
            onWeeklyScheduleChange={this.onWeeklyScheduleChange}
            onCopySchedule={this.onCopySchedule}
            onTabChange={this.onTabChange}
            onRevertSchedule={this.onRevertSchedule}
            isDumper={this.state.location && this.state.location.locationTypeId && this.state.locationTypes.find((lt) => lt.locationTypeId === this.state.location.locationTypeId && lt.locationTypeName === 'Dumper')}
          />
        </ErrorPage>
      </>
    );
  }
}

export default LocationController;
