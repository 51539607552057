import React from 'react';
import {
  Card, TimePicker, Input, Table, Button, Popconfirm, Select, Alert,
} from 'antd';
import { SaveOutlined, DeleteOutlined, UndoOutlined } from '@ant-design/icons';
import TimeInput from '../Common/TimeInput';

const { Option } = Select;

const ScheduleTable = (props) => {
  const {
    tableLoading, extra, title, scheduleType, dataSource, emptyText, onAddSchedule, onEditSchedule, onDeleteSchedule, onSaveSchedule, onRevert, dirty,
  } = props;
  const errorMessage = props[`${scheduleType}ErrorMessage`];

  const columns = [
    {
      title: 'Start Day',
      dataIndex: 'startDay',
      width: '23%',
      render: (startDay, record) => (
        <Select placeholder="Select a day" style={{ width: 120 }} value={startDay} onChange={(event) => onEditSchedule(scheduleType, record.scheduleId, event, 'startDay')}>
          <Option value={0}>Sunday</Option>
          <Option value={1}>Monday</Option>
          <Option value={2}>Tuesday</Option>
          <Option value={3}>Wednesday</Option>
          <Option value={4}>Thursday</Option>
          <Option value={5}>Friday</Option>
          <Option value={6}>Saturday</Option>
        </Select>
      ),
    },
    {
      title: 'Start Time',
      dataIndex: 'startTime',
      width: '23%',
      render: (startTime, record) => (
        <Input type="time" value={startTime} onChange={(event) => onEditSchedule(scheduleType, record.scheduleId, event, 'startTime')} />
        // <TimeInput value={startTime} onChange={event => onEditSchedule(scheduleType, record.scheduleId, event, 'startTime')} />
        // <TimePicker popupStyle={{ height: '400px' }} use12Hours format="hh:mm A" showNow={false} minuteStep={15} value={startTime} onChange={event => onEditSchedule(scheduleType, record.scheduleId, event, 'startTime')} />
      ),
    },
    {
      title: 'End Day',
      dataIndex: 'endDay',
      width: '23%',
      render: (endDay, record) => (
        <Select style={{ width: 120 }} value={endDay} onChange={(event) => onEditSchedule(scheduleType, record.scheduleId, event, 'endDay')}>
          <Option disabled={record.startDay > 0} value={0}>Sunday</Option>
          <Option disabled={record.startDay > 1} value={1}>Monday</Option>
          <Option disabled={record.startDay > 2} value={2}>Tuesday</Option>
          <Option disabled={record.startDay > 3} value={3}>Wednesday</Option>
          <Option disabled={record.startDay > 4} value={4}>Thursday</Option>
          <Option disabled={record.startDay > 5} value={5}>Friday</Option>
          <Option disabled={record.startDay > 6} value={6}>Saturday</Option>
        </Select>
      ),
    },
    {
      title: 'End Time',
      dataIndex: 'endTime',
      width: '23%',
      render: (endTime, record) => (
        <Input type="time" value={endTime} onChange={(event) => onEditSchedule(scheduleType, record.scheduleId, event, 'endTime')} />
        // <Input value={endTime} onChange={event => onEditSchedule(scheduleType, record.scheduleId, event, 'endTime')} />

        // <TimePicker value={endTime} onChange={event => onEditSchedule(scheduleType, record.scheduleId, event, 'endTime')} />
      ),
    },
    {
      title: '',
      dataIndex: 'operation',
      width: '8%',
      render: (text, record) => (
        <Popconfirm title="Are you sure you want to delete?" onConfirm={() => onDeleteSchedule(scheduleType, record.scheduleId)}>
          <Button icon={<DeleteOutlined />} />
        </Popconfirm>

      // <Button icon={<DeleteOutlined />} onClick={() => onDeleteSchedule(scheduleType, record.scheduleId)} />
        // <Popconfirm title="Are you sure you want to delete?" onConfirm={() => onDeleteSchedule(scheduleType, record.scheduleId)}>
        //   <a>Delete</a>
        // </Popconfirm>
      ),
    },
  ];

  return (
    <Card size="small" title={title} extra={extra}>
      {errorMessage && <Alert style={{ marginBottom: 16 }} message={errorMessage} type="error" showIcon />}
      <Table
        loading={tableLoading}
        locale={{
          emptyText,
        }}
        size="small"
        rowKey="scheduleId"
        // components={components}
        // rowClassName={() => 'editable-row'}
        bordered
        dataSource={dataSource}
        columns={columns}
        pagination={false}
      />
      <Button disabled={!dirty || errorMessage} style={{ marginTop: '16px', marginRight: '8px' }} type="primary" icon={<SaveOutlined />} onClick={() => onSaveSchedule(scheduleType)}>Save</Button>
      <Button disabled={!dirty} style={{ marginTop: '16px' }} icon={<UndoOutlined />} onClick={() => onRevert()}>Revert</Button>
    </Card>
  );
};

export default ScheduleTable;
