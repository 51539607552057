import _, { toUpper } from 'lodash';
import React, { Component } from 'react';
import { Alert, Card } from 'antd';
import MobileDetect from 'mobile-detect';
import { Auth } from 'aws-amplify';
import { withRouter } from 'react-router-dom';
import AppLayout from './AppLayout';
import MobileLayout from '../Mobile/MobileLayout';
import MobileController from '../Mobile/MobileController';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {

    };

    this.onLogoutClick = this.onLogoutClick.bind(this);
    this.onCompanySelect = this.onCompanySelect.bind(this);
  }

  async componentDidMount() {
    const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
    const { username } = user;
    const groups = user.signInUserSession.accessToken.payload['cognito:groups'];

    let companies = null;
    let selectedCompany = null;
    let isCanforAdmin = false;
    let isCanforStaff = false;

    function getQueryVariable(location, variable) {
      const query = location.search.substring(1);
      const vars = query.split('&');
      for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) == variable) {
          return decodeURIComponent(pair[1]);
        }
      }
    }

    if (groups) {
      isCanforAdmin = !!groups.find((g) => g === 'Canfor:Admin');
      isCanforStaff = !!groups.find((g) => g === 'Canfor:Staff');
      companies = groups.filter((g) => !['Canfor:Staff', 'Canfor:Admin'].includes(g));
      companies = companies.map((g) => {
        const tokens = g.split(':'); return {
          key: g, name: tokens[0], role: tokens[1], title: `${tokens[0]} ${tokens[1]}`, isDispatcher: (tokens[1] === 'Dispatcher'),
        };
      });
      companies = _.orderBy(companies, ['title']);

      const qs = getQueryVariable(this.props.location, 'company');
      const c = qs && companies.find((c) => c.key === qs);

      if (c) {
        selectedCompany = c;
      } else {
        selectedCompany = companies[0];
      }
    }

    this.setState({
      username,
      companies,
      selectedCompany,
      isCanforAdmin,
      isCanforStaff,
    });
  }

  async onLogoutClick() {
    await Auth.signOut();
  }

  onCompanySelect(e) {
    if (!e.key.startsWith('company_')) {
      return;
    }
    const { key } = e;
    const realKey = key.split('_')[1];
    const selectedCompany = this.state.companies.find((c) => c.key === realKey);
    this.props.history.push(`?company=${selectedCompany.key}`);

    this.setState({
      selectedCompany,
    }, () => {
      this.forceUpdate();
    });
  }

  render() {
    if (!this.state.username) {
      return null;
    }

    if (!this.state.companies) {
      return (
      // <Card className="center">
        <Alert

          className="center"
         // style={{ width: '640px' }}
          message="Account setup not complete"
          description={(
            <div style={{ marginTop: 12 }}>
              <p>
                Your user account (
                {this.state.username}
                ) has not been added to any groups.
              </p>
              <p>Please contact your Canfor Contract Supervisor.</p>
            </div>
)}
          type="error"
          showIcon
        />
      );
    }

    if (!this.state.selectedCompany.isDispatcher) {
      return (<MobileLayout {...this.state} onCompanySelect={this.onCompanySelect} onLogoutClick={this.onLogoutClick} />);
    }
    return (<AppLayout {...this.state} onCompanySelect={this.onCompanySelect} onLogoutClick={this.onLogoutClick} />);
  }
}

export default withRouter(App);
