import NProgress from 'nprogress';
import React from 'react';

class ProgressBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      display: false,
    };
  }

  componentDidMount() {
    NProgress.configure({
      parent: this.props.parent,
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.display && nextProps.display === false) {
      this.setState({
        display: false,
      });
      NProgress.done();
    }

    if (!this.state.display && nextProps.display) {
      this.setState({
        display: true,
      });
      NProgress.start();
    }

    return false;
  }

  render() {
    return null;
  }
}

export default ProgressBar;
