import { Card } from 'antd';
import React from 'react';

const NotFound = () => (
  <Card className="center">
    <h1>Page Not Found</h1>
    <p>The page you are trying to access does not exist.</p>
  </Card>
);

export default NotFound;
