import { InputNumber, Table, Checkbox } from 'antd';
import React from 'react';

const LocationPreferencesTable = (props) => {
  const {
    filters,
    loading,
    rows,
    size,

    onSortChange,
    onTableChange,
    onVisibleCheck,
  } = props;

  const columns = [
    {
      title: 'Location',
      dataIndex: 'locationName',
    },
    {
      title: 'Visible',
      dataIndex: 'visible',
      className: 'align-center-column',
      render: (visible, row) => (
        <Checkbox
          checked={visible}
          onChange={(e) => onVisibleCheck(row.locationId, e.target.checked)}
        />
      ),
      filteredValue: filters.visible || null,
      filters: [{ text: 'Hidden', value: 'Hidden' }, { text: 'Visible', value: 'Visible' }],
      onFilter: (filterValue, record) => (!!record.visible) === (filterValue === 'Visible'),
      width: '15%',
    },
    {
      title: 'Sort',
      dataIndex: 'sort',
      className: 'align-center-column',
      style: { margin: 'auto' },
      render: (sort, row) => (
        <InputNumber
          type="number"
          disabled={!row.visible}
          value={sort}
          min={1}
          max={999}
          onChange={(value) => onSortChange(row.locationId, value)}
        />
      ),
      width: '15%',
    },
  ];

  return (
    <div className="mywrapperthing" style={{ height: '100%' }}>
      <Table
        bordered
        columns={columns}
        dataSource={rows}
        loading={loading}
        rowKey="locationId"
        onChange={onTableChange}
        size="small"
        locale={{
          emptyText: 'This company has not been granted access to any locations.',
        }}
        pagination={false}
        scroll={{ y: size.height - 48 }}
        style={{ height: size.height - 48 }}
      />
    </div>
  );
};

export default LocationPreferencesTable;
