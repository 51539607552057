import React from 'react';

const AppFooter = () => (
  <div className="footer">
    <div className="copyright">
      {`${process.env.REACT_APP_LONG_NAME} `}
      {`(${process.env.REACT_APP_SHORT_NAME}) `}
      ©2019 Canfor.
    </div>
    <div className="implemented">
      {'Implemented by '}
      <a href="https://www.ascentech.ca" target="_blank" rel="noopener noreferrer">AscenTech</a>
    </div>
    <div className="build">
      {'Build: '}
      {process.env.REACT_APP_BUILD_VERSION}
    </div>
  </div>
);

export default AppFooter;
