import React from 'react';
import { SizeMe } from 'react-sizeme';

import BinBankPreferencesFilters from './BinBankPreferencesFilters';
import BinBankPreferencesTable from './BinBankPreferencesTable';

const BinBankPreferencesPage = (props) => (
  <div className="preferences-tab">
    {/* {props.error && <Alert type="error" showIcon message={props.error} style={{ marginBottom: '16px' }} /> } */}
    <div className="preferences-table-wrapper">
      <BinBankPreferencesFilters {...props} />
      <SizeMe monitorHeight>
        {({ size }) => <BinBankPreferencesTable {...props} size={size} />}
      </SizeMe>
    </div>
  </div>
);

export default BinBankPreferencesPage;
