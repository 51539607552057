import { Spin } from 'antd';
import moment from 'moment';
import React from 'react';
import {
  Text, LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Brush, ResponsiveContainer,
} from 'recharts';
import ErrorAlert from '../Common/ErrorAlert';

const AxisTick = (props) => {
  const { x, y, payload } = props;
  const date = moment.unix(payload.value);
  const formatted = `${date.format('h:mm')} ${date.format('A')}`;

  return <Text x={x} y={y} width={20} textAnchor="middle" verticalAnchor="start">{formatted}</Text>;
};

const Chart = (params) => {
  const {
    chartError,
    chartLoaded,
    chartLoading,
    data,
    lineMeta,
    onBrushChange,
    startIndex,
    endIndex,
  } = params;

  if (chartError) {
    return <ErrorAlert error={chartError} />;
  }

  if (!chartLoaded && chartLoading) {
    return <Spin />;
  }

  if (!data) {
    return null;
  }

  const lines = lineMeta.map((l) => (
    <Line
      key={l.key}
      dataKey={l.key}
      dot={false}
      strokeWidth={2}
      stroke={l.color}
    />
  ));

  return (
    <ResponsiveContainer>
      <LineChart
        className="chart"
        margin={{
          top: 20, right: 20, left: 20, bottom: 20,
        }}
        data={data}
      >
        {lines}
        <XAxis
          dataKey="dateUnix"
          tick={<AxisTick />}
          height={60}
        />
        <YAxis
          type="number"
          domain={[0, 100]}
          ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          interval={0}
        />
        <CartesianGrid strokeDasharray="3 3" />
        <Legend
          height={40}
          verticalAlign="top"
          margin={{ bottom: 40 }}
          formatter={(value) => lineMeta.find((b) => b.key === value).bin}
        />
        <Brush
          dataKey="dateF"
          stroke="#2767ad"
          gap={6}
          travellerWidth={16}
          onChange={onBrushChange}
          startIndex={startIndex}
          endIndex={endIndex}
        />
        <Tooltip
          labelFormatter={(v) => moment.unix(v).format('hh:mm A z')}
          formatter={(value, name) => {
            const formattedName = lineMeta.find((m) => m.key === name).bin;
            const formattedValue = `${Math.round(value)}%`;
            return [formattedValue, formattedName];
          }}
        />

      </LineChart>
    </ResponsiveContainer>
  );
};

export default Chart;
