import React from 'react';
import {
  Row, Card, Col, Button, Alert, Table,
} from 'antd';

const AccountPage = ({ accounts, loading, onResetDispatchClick }) => {
  const columns = [
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Last',
      dataIndex: 'lastResetDate',
      key: 'lastResetDate',
    },
    {
      title: '',
      dataIndex: 'Username',
      key: 'Username',
      render: (username, user) => <Button disabled={!user.canReset} onClick={() => onResetDispatchClick(user.username)}>Reset Password</Button>,
    }];

  return (
    <div className="accounts-page">
      <div className="accounts-table-wrapper">
        <Table
          bordered
          columns={columns}
          dataSource={accounts}
          loading={loading}
          rowKey="username"
        // rowSelection={rowSelectionOptions}
        // onChange={onTableChange}
          size="small"
          locale={{
          // emptyText: 'No bins found'
          }}
          pagination={false}
        />
        {/* <Table dataSource={accounts} columns={columns} loading={loading} /> */}
      </div>
    </div>
    // <Row gutter={16}>
    //   <Col span={8}>
    // <Alert message="Please ensure you have access to XXX_EMAIL_XXX as you will not be able to
    //         login without the code."></Alert>
    //     <Card title="Dispatch Account">
    //       <p>
    //         Reset the password associated with this Dispatch account.
    //       </p>
    //       <Button onClick={onResetDispatchClick} disabled={loading}>Reset Dispatch</Button>
    //     </Card>
    //   </Col>
    //   <Col span={8}>
    //     <Card title="Trucker Account(s)">
    //       hello
    //     </Card>
    //   </Col>
    // </Row>
  );
};

export default AccountPage;
