import moment from 'moment-timezone';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { getDispatch } from '../api';
import DispatchPage from './DispatchPage';
import ErrorPage from '../Common/ErrorPage';

const initialState = {
  error: null,
  lastRefresh: null,
  loaded: false,
  loading: false,
  localTimezone: moment.tz.guess(),
  sites: [],
  timerSeconds: 0,
  timerFormatted: '-',
  timezoneType: 'Local',
};

const REFRESH_SECONDS = 15;

const formatSeconds = (seconds) => {
  const duration = moment.duration(seconds, 'seconds');
  const min = duration.minutes();
  const sec = duration.seconds();

  return min > 0 ? `${min}m ${sec}s` : `${sec}s`;
};

class DispatchController extends Component {
  constructor(props) {
    super(props);

    this.state = { ...initialState };

    this.onRefreshClick = this.onRefreshClick.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.onTimeZoneTypeChange = this.onTimeZoneTypeChange.bind(this);
  }

  componentDidMount() {
    this.loadDispatch();

    const refreshInterval = window.setInterval(() => {
      const newSeconds = this.state.timerSeconds - 1;

      this.setState({
        timerSeconds: newSeconds,
        timerFormatted: formatSeconds(newSeconds),
      }, () => {
        if (this.state.timerSeconds <= 0) {
          this.setState({
            timerSeconds: REFRESH_SECONDS,
            timerFormatted: formatSeconds(REFRESH_SECONDS),
          }, () => {
            this.loadDispatch();
          });
        }
      });
    }, 1 * 1000);

    this.setState({
      refreshInterval,
      timerSeconds: REFRESH_SECONDS,
      timerFormatted: `${REFRESH_SECONDS} s`,
    });
  }

  componentDidUpdate(prevProps) {
    // Only update parent changes if the user changed the selected company.
    if (this.props.selectedCompany && prevProps.selectedCompany && this.props.selectedCompany.name !== prevProps.selectedCompany.name) {
      this.setState({ ...({ ...initialState }) }, () => {
        this.loadDispatch();
      });
    }
  }

  componentWillUnmount() {
    const { refreshInterval } = this.state;

    if (refreshInterval) {
      clearInterval(refreshInterval);
    }
  }

  onRefreshClick() {
    this.loadDispatch();
  }

  onRowClick(record) {
    const { history } = this.props;
    history.push(`/chart/${record.binBank}`);
  }

  onTimeZoneTypeChange(e) {
    this.setState({
      timezoneType: e.target.value,
    });
  }

  loadDispatch() {
    this.setState({
      loading: true,
      error: null,
    }, async () => {
      try {
        const { selectedCompany: { name } } = this.props;
        const sites = await getDispatch(name, false);

        this.setState({
          lastRefresh: new Date().toISOString(),
          loaded: true,
          loading: false,
          sites,
        });
      } catch (error) {
        this.setState({
          error,
          lastRefresh: null,
          loaded: true,
          loading: false,
          sites: [],
        });
      }
    });
  }

  render() {
    return (
      <ErrorPage error={this.state.error}>
        <DispatchPage
          {...this.state}
          onRefreshClick={this.onRefreshClick}
          onRowClick={this.onRowClick}
          onTimeZoneTypeChange={this.onTimeZoneTypeChange}
        />
      </ErrorPage>
    );
  }
}

export default withRouter(DispatchController);
