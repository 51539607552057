import { Card } from 'antd';
import React from 'react';

const NotAuthorized = () => (
  <Card className="center">
    <h1>Not Authorized</h1>
    <p>You are not authorized to view this page.</p>
  </Card>
);

export default NotAuthorized;
