import React from 'react';
import {
  Card, Row, Col, Tag, Progress, Icon,
} from 'antd';
import ProductSegment from './ProductSegment';

const DispatchCard = (props) => {
  const {
    siteId, onRowClick, sawmill, planer, site,
  } = props;
  const productSegments = (
    <Col span={24}>
      <ProductSegment {...props} onRowClick={onRowClick} />
    </Col>
  );

  const sawmillStatus = sawmill.running ? (
    <Tag>
      {sawmill.uptime}
      %
    </Tag>
  ) : (
    <Tag color="red">
      {sawmill.uptime}
      %
    </Tag>
  );
  const planerStatus = planer.running ? (
    <Tag>
      {planer.uptime}
      %
    </Tag>
  ) : (
    <Tag color="red">
      {planer.uptime}
      %
    </Tag>
  );

  const colProps = {
    // span: 6,
    style: {
      textAlign: 'center',
      lineHeight: '32px',
    },
  };

  return (
    <Card key={siteId} title={null} className="dispatch-card">
      <Row gutter={16} style={{ height: 80, padding: 12 }}>
        <Col xs={24} sm={4} style={{ verticalAlign: 'middle' }}>
          <span className="dispatch-site">{site}</span>
        </Col>
        <Col xs={24} sm={10}>
          <Card className="dispatch-header-card" style={{ padding: 4 }}>
            <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
              {/* <Col {...colProps}><span className={sawmill.running ? 'running' : 'shutdown'}><b>Sawmill</b></span></Col> */}
              {/* <Col span={5} style={{ textAlign: 'center' }}> */}
              <span>
                Saw Mill
              </span>
              {/* <span>
                  Saw
                  <br />
                  Mill
                </span> */}
              {/* </Col> */}
              {/* <Col span={4} {...colProps}>
                <b>Sawmill</b>
              </Col> */}
              {/* <Col span={5} style={{ textAlign: 'center', fontSize: 24 }}> */}
              <span>{sawmill.running ? <Tag>Online</Tag> : <Tag className="offline-tag">Offline</Tag>}</span>
              {/* <span style={{ fontSize: 24 }}>{sawmill.running ? <Icon type="up-circle" /> : <Icon type="down-circle" style={{ color: 'red' }} />}</span> */}
              {/* </Col> */}
              {/* <Col {...colProps}>{sawmillStatus}</Col> */}
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Progress type="dashboard" status="normal" percent={sawmill.uptime} width={32} />
                <div style={{ fontSize: 'smaller', marginTop: '-4px' }}>Uptime</div>
              </div>
              {/* <div> */}
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Progress type="dashboard" status="normal" percent={sawmill.target} width={32} />
                <div style={{ fontSize: 'smaller', marginTop: '-4px' }}>Target</div>
              </div>
              {sawmill.species}
              {/* </Row> */}
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={10}>
          <Card className="dispatch-header-card" style={{ padding: 4 }}>
            <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
              {/* <Row> */}
              {/* <Col {...colProps}><span className={planer.running ? 'running' : 'shutdown'}><b>Planer</b></span></Col> */}
              {/* <Col span={5} style={{ textAlign: 'center' }}> */}
              <span>
                Planer Mill
              </span>
              {/* <span>
                  Planer
                  <br />
                  Mill
                </span> */}
              {/* </Col> */}
              {/* <Col span={5} style={{ textAlign: 'center' }}> */}
              <span>{planer.running ? <Tag>Online</Tag> : <Tag className="offline-tag">Offline</Tag>}</span>
              {/* <span style={{ fontSize: 24 }}>{planer.running ? <Icon type="up-circle" /> : <Icon type="down-circle" style={{ color: 'red' }} />}</span> */}
              {/* </Col> */}
              {/* <Col {...colProps}>{planerStatus}</Col> */}
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Progress type="dashboard" status="normal" percent={planer.uptime} width={32} />
                <div style={{ fontSize: 'smaller', marginTop: '-4px' }}>Uptime</div>
              </div>
              {/* <div> */}
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Progress type="dashboard" status="normal" percent={planer.target} width={32} />
                <div style={{ fontSize: 'smaller', marginTop: '-4px' }}>Target</div>
              </div>

              <span>
                {sawmill.species}
                <br />
                {planer.product}
              </span>

            </div>
          </Card>
        </Col>
      </Row>
      <Row>
        {productSegments}
      </Row>
    </Card>
  );
};

export default DispatchCard;
