import { Table, Checkbox } from 'antd';
import React from 'react';

const CompanyLocationTable = (props) => {
  const {
    companyId,
    loading,
    rows,
    size,
    onAccessCheck,
  } = props;

  const columns = [
    {
      title: 'Location',
      dataIndex: 'locationName',
    },
    {
      title: 'Has Access',
      dataIndex: 'access',
      className: 'align-center-column',
      render: (access, row) => (
        <Checkbox
          checked={access}
          onChange={(e) => onAccessCheck(companyId, row.locationId, e.target.checked)}
        />
      ),
      width: '15%',
    },
  ];

  return (
    < div className="preferences-table-wrapper" >
      <Table
        bordered
        columns={columns}
        dataSource={rows}
        loading={loading}
        rowKey="locationId"
        size="small"
        locale={{
          emptyText: 'No locations have been setup',
        }}
        pagination={false}
        scroll={{ y: 500 - 48 }}
        style={{ height: 500 - 48 }}
      />
    </div >
  );
};

export default CompanyLocationTable;
