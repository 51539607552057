import {
  Modal, Checkbox, Col, Row,
} from 'antd';
import React from 'react';

import ColorPopover from '../Common/ColorPopover';

const BinBankPreferencesModal = ({
  color,
  modalVisible,
  preferencesCount,
  visible,

  onCancelClick,
  onColorChange,
  onSaveClick,
  onVisibleChange,
}) => (
  <Modal title="Multi Preference Selection" width={320} visible={modalVisible} okText="Save" onOk={onSaveClick} onCancel={onCancelClick} closable={false}>
    <Row style={{ marginBottom: 16 }}>
      <Col span={24}>
        Setting preferences for
        {' '}
        {preferencesCount}
        {' '}
        Bin Banks.
      </Col>
    </Row>
    <Row>
      <Col span={6}>Visible:</Col>
      <Col span={6}><Checkbox checked={visible} onChange={onVisibleChange} /></Col>
      <Col span={6}>Colour:</Col>
      <Col span={6}><ColorPopover color={color} onColorChange={onColorChange} /></Col>
    </Row>
  </Modal>
);

export default BinBankPreferencesModal;
