import React from 'react';
import { Progress, Table } from 'antd';
import moment from 'moment-timezone';

function round(value, precision) {
  const multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}

const BinBankCard = ({ product, binBank, localTimezone }) => {
  const columns = [
    {
      dataIndex: 'bin',
      width: '35%',
    },
    {
      dataIndex: 'currentM3',
      width: '15%',
      render: (currentM3) => (
        <span>
          {round(currentM3, 1)}
          {' '}
          m
          <sup>3</sup>
        </span>
      ),
    },
    {
      dataIndex: 'currentODT',
      width: '15%',
      render: (currentODT) => `${round(currentODT, 1)} ODT`,
    },
    {
      title: 'ODT',
      dataIndex: 'currentPercent',
      width: '35%',
      render: (currentPercent) => {
        let strokeColor;
        const percent = Math.round(currentPercent);
        if (percent < 40) {
          strokeColor = '#1CAF83';
        } else if (percent < 80) {
          strokeColor = '#FFCF29';
        } else {
          strokeColor = '#E95100';
        }

        return <Progress percent={percent} size="small" strokeLinecap="square" strokeColor={strokeColor} strokeWidth={12} status="normal" />;
      },
    },
  ];

  let estFull;

  if (binBank.estimatedTimeFullUtc) {
    const time = moment.tz(binBank.estimatedTimeFullUtc, localTimezone).format('hh:mm A z');
    estFull = (
      <b>
        Est Full:
        {time}
      </b>
    );
  } else {
    estFull = '';
  }

  return (
    <div style={{ background: 'white', padding: 8 }}>
      <div>
        <span><b>{binBank.binBankDescription}</b></span>
        <span style={{ float: 'right' }}>{estFull}</span>

      </div>
      <Table columns={columns} dataSource={binBank.bins} size="small" pagination={false} showHeader />
    </div>
  );
};

export default BinBankCard;
