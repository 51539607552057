import { Menu, Button } from 'antd';
import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import {
  AimOutlined, CameraOutlined, DesktopOutlined, LineChartOutlined, ScheduleOutlined, TeamOutlined, UserOutlined, BankOutlined,
} from '@ant-design/icons';
import CompanySelector from './CompanySelector';

const AppHeader = (params) => {
  const {
    location, onLogoutClick, username, selectedCompany, companies, onCompanySelect, isCanforAdmin, isCanforStaff,
  } = params;

  return (
    <Menu
      selectedKeys={[`/${location.pathname.split('/')[1]}`]}
      theme="dark"
      mode="horizontal"
      onSelect={onCompanySelect}
    >
      <Menu.Item key="/logo">
        <div className="tester">
          <div className="logo" />
        </div>
      </Menu.Item>
      <Menu.Item key="/dispatch">
        <NavLink to="/dispatch">
          <DesktopOutlined />
          Dispatch
        </NavLink>

      </Menu.Item>
      <Menu.Item key="/cameras" disabled={!selectedCompany.isDispatcher}>
        <NavLink to="/cameras">
          <CameraOutlined />
          Cameras
        </NavLink>

      </Menu.Item>
      <Menu.Item key="/chart" disabled={!selectedCompany.isDispatcher}>
        <NavLink to="/chart">
          <LineChartOutlined />
          Charts
        </NavLink>

      </Menu.Item>
      <Menu.Item key="/preferences" disabled={!selectedCompany.isDispatcher}>
        <NavLink to="/preferences">
          <ScheduleOutlined />
          Preferences
        </NavLink>
      </Menu.Item>
      <Menu.Item key="/account" disabled={!selectedCompany.isDispatcher}>
        <NavLink to="/account">
          <TeamOutlined />
          Accounts
        </NavLink>
      </Menu.Item>
      { (isCanforAdmin) && (
      <Menu.Item key="/locations">
        <NavLink to="/locations">
          <AimOutlined />
          Locations
        </NavLink>
      </Menu.Item>
      )}
      { (isCanforAdmin) && (
      <Menu.Item key="/companies">
          <NavLink to="/companies">
            <BankOutlined />
          Companies
        </NavLink>
      </Menu.Item>
      )}
      <Menu.Item key="/logout" style={{ float: 'right' }}>
        <Button type="primary" style={{ marginLeft: 8 }} onClick={onLogoutClick}>Logout</Button>
      </Menu.Item>
      <Menu.Item key="/user" style={{ float: 'right' }}>
        <a>
          <UserOutlined />
          {username}
        </a>
      </Menu.Item>
      <CompanySelector
        style={{ float: 'right' }}
        companies={companies}
        onCompanySelect={onCompanySelect}
        selectedCompany={selectedCompany}
      />

    </Menu>
  );
};

// Wrap the component with withRouter so we can access props.location.
export default withRouter(AppHeader);
