import React from 'react';
import {
  Menu, Icon, Button, Cascader,
} from 'antd';
import { NavLink, withRouter } from 'react-router-dom';
import { CameraOutlined, DesktopOutlined } from '@ant-design/icons';

const MobileMenu = ({
  location, onSiteSelected, selectedSiteKey, menuCollapsed, sites, onLogoutClick,
}) =>
// const options = sites.map((s) => {
//   const subitems = s.products.map((p) => {
//     const subKey = `${s.siteId}.${p.product}`;
//     return { value: subKey, label: p.product };
//   });

//   return {
//     label: s.site,
//     value: s.siteId,
//     children: subitems,
//   };
// });

  (
    <Menu
      selectedKeys={[`/${location.pathname.split('/')[1]}`]}
      theme="dark"
      mode="horizontal"
    >
      {/* <Menu.Item>
        <Cascader
          popupClassName="bin-bank-cascader"
          allowClear={false}
          placeholder="Select a Bin Bank"
          // style={{ width: '200px' }}
          options={options}
          onChange={onSiteSelected}
        />
      </Menu.Item> */}
      {/* disabled={!selectedCompany.isDispatcher} */}
      <Menu.Item key="/dispatch">
        <NavLink to="/dispatch">
          <DesktopOutlined />
          Dispatch
        </NavLink>

      </Menu.Item>
      <Menu.Item key="/cameras">
        <NavLink to="/cameras">
          <CameraOutlined />
          Cameras
        </NavLink>

      </Menu.Item>
      <Menu.Item key="/logout" style={{ float: 'right' }}>
        <Button type="primary" style={{ marginLeft: 8 }} onClick={onLogoutClick}>Logout</Button>
      </Menu.Item>
      {/* <Menu.Item style={{ float: 'right' }}>
        <Button type="primary" icon="logout" style={{ marginLeft: 8 }} onClick={onLogoutClick}></Button>
      </Menu.Item> */}
    </Menu>
  );
export default withRouter(MobileMenu);
// export default MobileMenu;
