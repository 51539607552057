import {
  Alert, Button, Modal, Input, Popconfirm,
} from 'antd';
import React, { Component } from 'react';
// import {
//   setLocationStatus, getCompanyLocations, getCameraUrls, getLights,
// } from '../api';
// import CamerasPage from './CamerasPage';
// import ErrorPage from '../Common/ErrorPage';

const maxLength = 250;

class LocationNotesModal extends Component {
  constructor(props) {
    super(props);
    console.log('THESE ARE ZEE PROPS', props);
    this.state = {
      originalText: props.notes,
      text: `${props.notes}`,
      textLength: props.notes.length,
      textLengthAlertType: 'info',
      loading: false,
      error: null,
      canSave: false,
      dirty: false,
    };

    this.onClearClick = this.onClearClick.bind(this);
    this.onSaveClick = this.onSaveClick.bind(this);
    this.onTextChange = this.onTextChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.notes !== this.props.notes) {
      this.setState({
        originalText: this.props.notes,
        text: `${this.props.notes}`,
        textLength: this.props.notes.length,
        textLengthAlertType: 'info',
        loading: false,
        error: null,
        canSave: false,
        dirty: false,
      })
    }
  }

  onSaveClick() {
    this.props.setNotes(this.state.text);
  }

  onClearClick() {
    this.onTextChange('');
  }

  onTextChange(text) {
    const textLength = text.length;

    if (textLength <= maxLength) {
      const dirty = JSON.stringify(text) !== JSON.stringify(this.state.originalText);

      this.setState({
        text,
        textLength,
        textLengthAlertType: 'info',
        dirty,
        canSave: dirty,
      });
    } else {
      this.setState({
        textLengthAlertType: 'error',
        canSave: false,
      });
    }
  }

  render() {
    const {
      canSave,
      dirty,
      error,
      text,
      textLength,
      textLengthAlertType,
    } = this.state;

    let exitButton;

    if (dirty) {
      exitButton = (
        <Popconfirm
          disabled={!dirty}
          okText="Yes"
          title="You have unsaved changes. Are you sure you want to exit?"
          onConfirm={() => this.props.onExit()}
        >
        <Button>Exit</Button>
      </Popconfirm>
      );
    }
    else {
      exitButton = <Button onClick={this.props.onExit}>Exit</Button>;
    }


    return (
    // <ErrorPage error={error}>
      <Modal
        title="Location Notes"
        width={480}
        visible
        okText="Save"
          // onOk={onSaveClick}
          // onCancel={onCancelClick}
        closable={false}
        footer={[
          // <div>{`${dirty}`}</div>,
          <Button disabled={textLength === 0} onClick={this.onClearClick}>
            Clear
          </Button>,
          <Button type="primary" disabled={!canSave} onClick={this.onSaveClick}>
            Save
          </Button>,
          exitButton,
        ]}
      >
        <Alert message={`Characters Used: ${textLength} / ${maxLength}`} type={textLengthAlertType} showIcon />
        <Input.TextArea value={text} onChange={(e) => this.onTextChange(e.target.value)} rows={8} />
      </Modal>
    // </ErrorPage>
    );
  }
}

export default LocationNotesModal;
