import React from 'react';
import { Button, Tooltip } from 'antd';
import ButtonGroup from 'antd/lib/button/button-group';
import { FilterOutlined, FilterTwoTone, ReloadOutlined } from '@ant-design/icons';

const LocationPreferencesFilters = ({
  canClearFilters,
  dirty,
  loading,
  saving,

  onClearFilterClick,
  onRefreshClick,
  onSaveClick,
}) => (
  <div style={{ marginBottom: 16, height: '32px' }}>
    <div style={{ float: 'left', width: 600 }}>
      <ButtonGroup>
        <Tooltip title="Clear All Filters">
          <Button disabled={!canClearFilters} onClick={onClearFilterClick}>
            {canClearFilters && <FilterTwoTone twoToneColor="red" />}
            {!canClearFilters && <FilterOutlined />}
          </Button>
        </Tooltip>
        <Tooltip title="Refresh Data">
          <Button onClick={onRefreshClick}>
            <ReloadOutlined />
          </Button>
        </Tooltip>
      </ButtonGroup>
    </div>
    <div style={{ float: 'right' }}>
      <Button type="primary" loading={saving || loading} disabled={!dirty || saving || loading} onClick={onSaveClick}>Save</Button>
    </div>
  </div>
);

export default LocationPreferencesFilters;
