import { Col, Row, Modal } from 'antd';
import React from 'react';

import CameraCard from './CameraCard';
import ProgressBar from '../Common/ProgressBar';

const CameraPage = ({
  locations,
  onCloseClick,
  onEditNotesClick,
  onImageClick,
  onSelectedImageClick,
  selectedImage,
  onStatusChange,
  canSetStatus,
  loading,
  mobile,
}) => {
  const cards = locations.map((location, index) => (
    <Col span={24} style={{ marginBottom: '16px' }}>
      <CameraCard location={location} onEditNotesClick={onEditNotesClick} onImageClick={onImageClick} onStatusChange={onStatusChange} canSetStatus={canSetStatus} mobile={mobile} />
    </Col>
  ));

  return (
    <div>
      <ProgressBar parent=".ant-layout-content" display={loading} />
      {selectedImage && (
        <Modal title={selectedImage.title} className="camera-image-modal" visible={!!selectedImage} onCancel={onCloseClick} footer={null}>
          <img style={{ width: '100%' }} alt={selectedImage.title} src={selectedImage.imgSrc} onClick={onSelectedImageClick} />
        </Modal>
      )}
      <Row type="flex" justify="space-around" gutter={16}>
        {cards}
      </Row>
    </div>
  );
};

export default CameraPage;
