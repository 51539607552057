import {
  Card, Row, Col, Cascader, Switch, DatePicker,
} from 'antd';
import moment from 'moment';
import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';

const ChartMenu = (params) => {
  const {
    date,
    hierarchy,
    hierarchyLoading,
    liveView,
    onBinBankChange,
    onDateChange,
    onLiveChange,
    selectedBinBank,
    timerRemaining,
  } = params;

  const cascaderOptions = hierarchy.map((s) => ({
    value: s.siteId,
    label: s.siteName,
    children: s.binBanks.map((bb) => ({
      value: bb.binBankId,
      label: bb.binBankDescription,
    })),
  }));

  const cascaderIcon = hierarchyLoading ? <LoadingOutlined className="bin-bank-cascader-loading" style={{ color: '#1890ff' }} /> : null;

  return (
    <Card className="chart-menu-card">
      <Row gutter={8}>
        <Col xs={24} md={12}>
          <div>
            <p className="chart-menu-header">Bin Bank</p>
            <Cascader
              allowClear={false}
              onChange={onBinBankChange}
              options={cascaderOptions}
              placeholder="Select a Bin Bank"
              popupClassName="bin-bank-cascader-popup"
              suffixIcon={cascaderIcon}
              value={selectedBinBank}
              style={{ width: '100%' }}
            />
          </div>
        </Col>
        <Col xs={8} md={5}>
          <div>
            <p className="chart-menu-header">Date</p>
            <DatePicker
              allowClear={false}
              // TODO - dont hardcode the first available date
              disabledDate={(value) => value.isAfter(moment()) || value.isBefore(moment('2019-01-01'))}
              onChange={onDateChange}
              value={date}
            />
          </div>
        </Col>
        <Col xs={8} md={3}>
          <div>
            <p className="chart-menu-header">Live</p>
            <Switch checked={liveView} onChange={onLiveChange} />
          </div>
        </Col>
        <Col xs={8} md={4}>
          <div>
            <p className="chart-menu-header">Refresh</p>
            <p>{timerRemaining}</p>
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default ChartMenu;
