import React from 'react';
import {
  Layout, Tabs, Cascader, Card, Row, Col, Button,
} from 'antd';
import SiteCard from './SiteCard';

const {
  Content, Sider, Header, Footer,
} = Layout;

const { TabPane } = Tabs;

const DispatchPage = ({
  sites, menuCollapsed, onMenuCollapse, selectedSiteKey, onSiteSelected, selectedProduct, selectedSite, onLogoutClick, localTimezone, onRefreshClick,
}) => {
  const siteItems = sites.map((s) => <TabPane tab={s.site} key={s.siteId}><SiteCard site={s} /></TabPane>);

  const options = sites.map((s) => {
    const subitems = s.products.map((p) => {
      const subKey = `${s.siteId}.${p.product}`;
      return { value: subKey, label: p.product };
    });

    return {
      label: s.site,
      value: s.siteId,
      children: subitems,
    };
  });

  return (
    <div>
      <Row style={{ padding: '8px' }}>
        <Col xs={18} lg={10}>
          <Cascader
            popupClassName="bin-bank-cascader-popup"
            allowClear={false}
            placeholder="Select a Bin Bank"
            options={options}
            onChange={onSiteSelected}
            style={{ width: '100%' }}
          />
        </Col>
        <Col xs={6}>
          <Button onClick={onRefreshClick}>Refresh</Button>
        </Col>
      </Row>
      { selectedProduct && (
      <div>
        <SiteCard siteName={selectedSite.site} product={selectedProduct} localTimezone={localTimezone} />
      </div>
      )}
    </div>
  );
};

export default DispatchPage;
