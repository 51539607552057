import React from 'react';
import { SizeMe } from 'react-sizeme';
import { Select, Tabs, Card } from 'antd';
import { AimOutlined, InboxOutlined } from '@ant-design/icons';
import CompanyBinBankTable from './CompanyBinBankTable';
import CompanyLocationTable from './CompanyLocationTable';

const { Option } = Select;
const { TabPane } = Tabs;

const CompanyPage = (props) => {
  const {
    loading,
    companyId,
    companies,
    onCompanyChange,
    tabKey,
    onTabChange,
    binBankAccess,
    locationAccess,
    onLocationAccessCheck,
    onBinBankAccessCheck,
  } = props;

  const companyOptions = companies.map((loc) => <Option key={loc} value={loc}>{loc}</Option>);

  const header = (
    <>
      <span style={{ marginRight: 8 }}>Company:</span>
      <Select
        showSearch
        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        notFoundContent="No companies have been created."
        loading={loading}
        value={companyId}
        style={{ width: 240 }}
        onChange={onCompanyChange}
      >
        {companyOptions}
      </Select>
    </>
  );

  return (
    <>
      <SizeMe monitorHeight>
        {({ size }) =>
          <Card title={header} style={{ marginBottom: 16 }}>
            {companyId
              && (
                <Card loading={loading} title={companyId} size="small">
                  <Tabs activeKey={tabKey} onChange={onTabChange}>
                    <TabPane
                      tab={(
                        <span>
                          <AimOutlined />
                          Locations
                        </span>
                      )}
                      key="locations"
                    >
                      <CompanyLocationTable size={size} rows={locationAccess} companyId={companyId} onAccessCheck={onLocationAccessCheck} />
                    </TabPane>
                    <TabPane
                      tab={(
                        <span>
                          <InboxOutlined />
                          {' '}
                          Bin Banks
                        </span>
                      )}
                      key="bins"
                    >
                      <CompanyBinBankTable size={size} rows={binBankAccess} companyId={companyId} onAccessCheck={onBinBankAccessCheck} />
                    </TabPane>
                  </Tabs>
                </Card>
              )}
          </Card>
        }
      </SizeMe>
    </>
  );
};

export default CompanyPage;
