import React from 'react';
import { SizeMe } from 'react-sizeme';

import LocationPreferencesFilters from './LocationPreferencesFilters';
import LocationPreferencesTable from './LocationPreferencesTable';

const LocationPreferencesPage = (props) => (
  <div className="preferences-tab">
    <div className="preferences-table-wrapper">
      <LocationPreferencesFilters onSaveClick={props.onSaveClick} {...props} />
      <SizeMe monitorHeight>
        {({ size }) => <LocationPreferencesTable {...props} size={size} />}
      </SizeMe>
    </div>
  </div>
);

export default LocationPreferencesPage;
