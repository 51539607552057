import React from 'react';
import { AimOutlined, InboxOutlined } from '@ant-design/icons';

import { Tabs } from 'antd';
import BinBankPreferencesController from '../BinBankPreferences/BinBankPreferencesController';
import LocationPreferencesController from '../LocationPreferences/LocationPreferencesController';

const { TabPane } = Tabs;

const PreferencesPage = (props) => (
  <div className="preferences-page">
    <Tabs defaultActiveKey="1">
      <TabPane
        tab={(
          <span>
            <InboxOutlined />
            {' '}
            Bin Banks
          </span>
)}
        key="1"
      >
        <BinBankPreferencesController {...props} />
      </TabPane>
      <TabPane
        tab={(
          <span>
            <AimOutlined />
            Locations
          </span>
)}
        key="2"
      >
        <LocationPreferencesController {...props} />
      </TabPane>
    </Tabs>
  </div>
);

export default PreferencesPage;
