import React, { Component } from 'react';

import {
  setLocationStatus, setLocationNotes, getCompanyLocations, getCameraUrls, getLights,
} from '../api';
import CamerasPage from './CamerasPage';
import ErrorPage from '../Common/ErrorPage';
import LocationNotesModal from './LocationNotesModal';

class CameraController extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: null,
      locations: [],
      notesModalLocationId: null,
    };
    // this.state = {
    //   cameras: [
    //     { imageKey: 'nwdcam1.jpg', title: 'Northwood West', lightKey: null },
    //     { imageKey: 'nwdcam2.jpg', title: 'Northwood East', lightKey: null },
    //     { imageKey: 'iccam1.jpg', title: 'Intercon West', lightKey: 'intDumper' },
    //     { imageKey: 'iccam2.jpg', title: 'Intercon South', lightKey: null },
    //     { imageKey: 'pgcam1.jpg', title: 'PG Pulp North', lightKey: 'pgDumper' },
    //     { imageKey: 'pgcam2.jpg', title: 'PG Pulp East', lightKey: null },
    //   ],
    //   selectedImage: null,
    // };

    this.onCloseClick = this.onCloseClick.bind(this);
    this.onEditNotesClick = this.onEditNotesClick.bind(this);
    this.onImageClick = this.onImageClick.bind(this);
    this.onSelectedImageClick = this.onSelectedImageClick.bind(this);
    this.onStatusChange = this.onStatusChange.bind(this);
    this.setNotes = this.setNotes.bind(this);
    this.onEditNotesExit = this.onEditNotesExit.bind(this);
  }

  async componentDidMount() {
    await this.loadLocations();
    // await this.refreshUrls();

    this.setState({
      // Start a 30 second time to refresh images and lights.
      refreshIntervalId: setInterval(() => {
        this.loadLocations();
        // this.refreshImages();

        // this.refreshLights();
      }, 30000),
    }, () => {

      // Immediately refresh the images and lights.
      // this.refreshImages();
      // this.refreshLights();
      // this.loadLocations();
    });
  }

  componentWillUnmount() {
    const { refreshIntervalId } = this.state;

    if (refreshIntervalId) {
      clearInterval(refreshIntervalId);
    }
  }

  onImageClick(locationId, cameraId) {
    const selectedImage = this.state.locations.find((l) => l.locationId === locationId).cameras.find((c) => c.cameraId === cameraId);

    if (selectedImage) {
      this.setState({
        selectedImage,
      });
    }
  }

  onSelectedImageClick() {
    this.setState({
      selectedImage: null,
    });
  }

  onCloseClick() {
    this.setState({
      selectedImage: null,
    });
  }

  onEditNotesClick(locationId) {
    console.log('MATCHY MATCHY', locationId, this.state.locations, this.state.locations.find((location) => location.locationId === locationId));
    this.setState({
      notesModalLocationId: locationId,
      notesText: this.state.locations.find((location) => location.locationId === locationId).notes,
    });
  }

  onEditNotesExit() {
    this.setState({
      notesModalLocationId: null,
      notesText: null,
    });
  }

  setStatus(locationId, status) {
    this.setState({
      // savingOverride: true,
      error: null,
    }, () => {
      // Default to white if not specified
        setLocationStatus(locationId, { status })
        .then(() => {
          this.setState({
            // savingOverride: false,
          }, () => {
            this.loadLocations();
            // this.loadLocation(this.state.locationId);
          });
        })
        .catch((error) => {
          this.setState({
            error: error.body,
            // savingOverride: false,
          });
        });
    });
  }

  setNotes(notes) {
    this.setState({
      // savingOverride: true,
      error: null,
    }, () => {
      // Default to white if not specified
        setLocationNotes(this.state.notesModalLocationId, { notes })
        .then(() => {
          this.setState({
            notesText: notes,
            // savingOverride: false,
          }, () => {
            this.loadLocations();
            // this.loadLocation(this.state.locationId);
          });
        })
        .catch((error) => {
          this.setState({
            error: error.body,
            // savingOverride: false,
          });
        });
    });
  }

  onStatusChange(locationId, status) {
    this.setStatus(locationId, status);
  }

  async loadLocations() {
    this.setState({
      loading: true,
      error: null,
    }, async () => {
      const locations = await getCompanyLocations(this.props.selectedCompany.name);
      const urls = await getCameraUrls(this.props.selectedCompany.name);

      const newLocations = await Promise.all(locations.map(async (location) => {
        const newCameras = await Promise.all(location.cameras.map(async (camera) => {
          const { url } = urls.find((u) => u.key === camera.filename);
          let imgSrc;
          try {
            imgSrc = await this.getImageSrc(url);
          } catch (err) {
            // Silent.
          }

          return { ...camera, url, imgSrc };
        }));
        return { ...location, cameras: newCameras };
      }));

      this.setState({
        loading: false,
        locations: newLocations,
        error: null,
      });
    });
  }

  async getImageSrc(url) {
    let expired = false;
    try {
      // Fetch from the signed url. Ensure that the image is not retrieved from cache
      const response = await fetch(url, { cache: 'no-store' });
      if (!response.ok && response.status === 403) {
        expired = true;
        throw new Error('Expired');
      }
      const buffer = await response.arrayBuffer();

      // Convert from buffer to base64
      let binary = '';
      const bytes = [].slice.call(new Uint8Array(buffer));
      bytes.forEach((b) => binary += String.fromCharCode(b));
      const base64 = window.btoa(binary);

      const imgSrc = `data:image/jpeg;base64,${base64}`;
      return imgSrc;
    } catch (error) {
      // If this is an expired error, then dont display on the page, just propogate the error
      // so we can get a new url.
      if (expired) {
        throw error;
      }
      // this.setState({ error });
    }
  }

  render() {
    return (
      <ErrorPage error={this.state.error}>
        <CamerasPage
          {...this.state}
          // locations={this.state.locations}
          mobile={this.props.mobile}
          onCloseClick={this.onCloseClick}
          onEditNotesClick={this.onEditNotesClick}
          onImageClick={this.onImageClick}
          onSelectedImageClick={this.onSelectedImageClick}
          onStatusChange={this.onStatusChange}
          canSetStatus={this.props.isCanforAdmin || this.props.isCanforStaff}
        />
        {
          this.state.notesModalLocationId !== null && <LocationNotesModal notes={this.state.notesText} onExit={this.onEditNotesExit} setNotes={this.setNotes} />
        }
      </ErrorPage>
    );
  }
}

export default CameraController;
