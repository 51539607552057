import {
  Col, Row, Card, Alert, Radio,
} from 'antd';
import moment from 'moment-timezone';
import React from 'react';
import { ReloadOutlined } from '@ant-design/icons';
import ErrorAlert from '../Common/ErrorAlert';
import ProgressBar from '../Common/ProgressBar';
import DispatchCard from './DispatchCard';

const RadioGroup = Radio.Group;

const DispatchPage = ({
  error,
  lastRefresh,
  loaded,
  loading,
  localTimezone,
  sites,
  timerFormatted,
  timezoneType,

  onRefreshClick,
  onRowClick,
  onTimeZoneTypeChange,
}) => {
  let content = null;

  if (loaded && sites.length === 0) {
    content = (
      <Alert
        className="center"
        style={{ width: '640px' }}
        message="No Preferences"
        description="This screen will not work until a Dispatcher sets up preferences for this group."
        type="warning"
        showIcon
      />
    );
  } else {
    const cards = sites.map((d) => (
      <Col style={{ marginTop: 16 }} xs={24} lg={12} xl={12} xxl={12}>
        <DispatchCard localTimezone={localTimezone} timezoneType={timezoneType} {...d} onRowClick={onRowClick} />
      </Col>
    ));

    const zone = moment.tz.zone(moment.tz.guess());
    const tz = zone.abbr(moment());

    content = (
      <>
        <Row gutter={16}>
          <Col xs={24} sm={12} xxl={8}>
            <Card>
              <RadioGroup onChange={onTimeZoneTypeChange} value={timezoneType}>
                <Radio value="Local">
                  Local Time (
                  {tz}
                  )
                </Radio>
                <Radio value="Site">Site Time</Radio>
              </RadioGroup>
            </Card>
          </Col>
          <Col xs={24} sm={12} xxl={8}>
            <Card>
              <Row>
                <Col xs={24} sm={4}><ReloadOutlined onClick={onRefreshClick} /></Col>
                <Col xs={24} sm={10} lg={8}>
                  Last at
                  {' '}
                  {moment(lastRefresh).format('hh:mm:ss A zz')}
                </Col>
                <Col xs={24} sm={10} lg={8}>
                  Next in
                  {' '}
                  {timerFormatted}
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row gutter={16}>
          {cards}
        </Row>
      </>
    );
  }

  return (
    <div className="dispatch-page">
      <ProgressBar parent=".ant-layout-content" display={loading} />
      {content}
    </div>
  );
};

export default DispatchPage;
