import React from 'react';
import ErrorAlert from './ErrorAlert';

const ErrorPage = ({ error, children }) => {
  if (!error) {
    return children;
  }

  return <ErrorAlert error={error} />;
};

export default ErrorPage;
