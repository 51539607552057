import moment from 'moment-timezone';
import NProgress from 'nprogress';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { getBinBankChart, getBinBank, getCompanyHierarchy } from '../api';
import ChartPage from './ChartPage';
import ErrorPage from '../Common/ErrorPage';

const formatSeconds = (s) => `${s}s`;

class ChartController extends Component {
  constructor(props) {
    super(props);

    this.state = {
      date: moment(),
      hierarchy: [],
      hierarchyLoading: false,
      liveView: true,
      statsLoaded: false,
      statsLoading: false,
      timerRemaining: 'N/A',
      timerSeconds: 0,
      timezone: moment.tz.guess(),
    };

    this.onDateChange = this.onDateChange.bind(this);
    this.onLiveChange = this.onLiveChange.bind(this);
    this.onBrushChange = this.onBrushChange.bind(this);
    this.onBinBankChange = this.onBinBankChange.bind(this);
  }

  async componentDidMount() {
    NProgress.configure({
      parent: '.ant-layout-content',
    });

    this.setState({
      hierarchyLoading: true,
    }, async () => {
      try {
        const hierarchy = await getCompanyHierarchy(this.props.selectedCompany.name);

        const selectedBinBankId = parseInt(this.props.match.params.binBank, 10);

        if (selectedBinBankId) {
          const findSite = hierarchy.find((s) => s.binBanks.find((bb) => bb.binBankId == selectedBinBankId));

          if (!findSite) {
            return;
          }

          this.setState({
            hierarchy,
            hierarchyLoading: false,
            binBankId: selectedBinBankId,
            siteId: findSite.siteId,
            // selectedBinBank: [findSite.siteId, selectedBinBankId],
          }, () => {
            this.onLiveChange(true);
            this.loadChart();
          });
        } else {
          this.setState({
            hierarchy,
            hierarchyLoading: false,
          });
        }
      } catch (error) {
        this.setState({ error });
      }
    });
  }

  componentWillUnmount() {
    const { countdownInterval } = this.state;

    if (countdownInterval) {
      clearInterval(countdownInterval);
    }
  }

  // Keep in mind it could be the first selection, or just changing from an old one.
  onBinBankChange(selectedBinBank) {
    this.setState({
      siteId: selectedBinBank[0],
      binBankId: selectedBinBank[1],
    }, () => {
      this.restartTimer();
      this.load();
    });
  }

  onBrushChange() {
    this.onLiveChange(false);
  }

  onDateChange(date) {
    this.setState({
      date,
    }, () => {
      this.onLiveChange(false);
      this.load();
    });
  }

  onLiveChange(liveView) {
    if (liveView) {
      this.setState({
        date: moment(),
        liveView,
      }, () => {
        this.restartTimer();
        this.load();
      });
    } else {
      this.setState({
        liveView,
      }, () => {
        this.restartTimer();
      });
    }
  }

  restartTimer() {
    try {
      let countdownInterval = null;
      let timerSeconds = 0;
      let timerRemaining = 'N/A';

      const { liveView } = this.state;

      clearInterval(this.state.countdownInterval);

      if (liveView) {
        timerSeconds = 30;
        timerRemaining = `${timerSeconds}s`;
        countdownInterval = window.setInterval(() => {
          const newSeconds = this.state.timerSeconds - 1;
          this.setState({
            timerSeconds: newSeconds,
            timerRemaining: formatSeconds(newSeconds),
          }, () => {
            try {
              if (this.state.timerSeconds <= 0) {
                this.setState({
                  timerSeconds: 30,
                  timerRemaining: formatSeconds(30),
                });
                this.load();
              }
            } catch (error2) {
              this.setState({ error: error2 });
            }
          });
        }, 1000); // Every second
      }

      this.setState({
        countdownInterval,
        timerSeconds,
        timerRemaining,
      });
    } catch (error) {
      this.setState({ error });
    }
  }

  async load() {
    try {
      Promise.all([
        this.loadChart(),
        this.loadStats(),
      ]);
    } catch (error) {
      this.setState({ error });
    }
  }

  async loadStats() {
    try {
      const { binBankId, liveView } = this.state;

      if (!liveView) {
        return;
      }

      this.setState({
        statsLoading: true,
      }, async () => {
        const stats = await getBinBank(this.props.selectedCompany.name, { binBankId });

        this.setState({
          stats,
          statsLoading: false,
          statsLoaded: true,
        });
      });
    } catch (error) {
      this.setState({ error });
    }
  }

  async loadChart() {
    try {
      const {
        binBankId,
        date,
      } = this.state;

      NProgress.start();

      this.setState({
        chartError: null,
        chartLoading: true,
      }, async () => {
        try {
          const chartData = await getBinBankChart({
            company: this.props.selectedCompany.name,
            binBankId,
            date: date.format('YYYY-MM-DD'),
          });

          const { bins, data, lastIndex } = chartData;

          const dataMapped = data.map((d) => ({
            ...d,
            dateF: moment(d.date).format('hh:mm a'),
            dateUnix: moment(d.date).unix(),
          }));

          const colors = ['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b', '#e377c2', '#7f7f7f', '#bcbd22', '#17becf'];

          const lineMeta = [];

          for (let i = 0; i < bins.length; i += 1) {
            const b = chartData.bins[i];
            const line = {
              key: `bin_${b.binId}`,
              color: colors[i],
              bin: b.bin,
            };
            lineMeta.push(line);
          }

          if (lineMeta.length > 1) {
            lineMeta.push({
              key: 'bin_avg',
              color: 'black',
              bin: 'All Bins',
            });
          }

          if (this.state.liveView) {
            const endIndex = Math.min(lastIndex + 3, data.length - 1);
            const startIndex = endIndex - (4 * 60 / 5);

            this.setState({
              data: dataMapped,
              lineMeta,
              startIndex,
              endIndex,
              chartLoading: false,
              chartLoaded: true,
            });
          } else {
            this.setState({
              data: dataMapped,
              lineMeta,
              chartLoading: false,
              chartLoaded: true,
            });
          }
          NProgress.done();
        } catch (error1) {
          this.setState({ chartError: error1 });
        }
      });
    } catch (error) {
      this.setState({ chartError: error });
    }
  }

  render() {
    return (
      <ErrorPage error={this.state.error}>
        <ChartPage
          {...this.state}
          selectedBinBank={this.state.binBankId ? [this.state.siteId, this.state.binBankId] : null} // {this.state.selectedBinBank}

          onBinBankChange={this.onBinBankChange}
          onBrushChange={this.onBrushChange}
          onDateChange={this.onDateChange}
          onLiveChange={this.onLiveChange}
        />
      </ErrorPage>
    );
  }
}

export default withRouter(ChartController);
