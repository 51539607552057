import React from 'react';
import {
  Input, Table, Button, Popconfirm, Alert,
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

const CameraTable = (props) => {
  const {
    location, onAddCamera, onEditCamera, onDeleteCamera, cameraErrorMessage,
  } = props;

  const columns = [
    {
      title: 'Name',
      dataIndex: 'camera',
      width: '30%',
      render: (cameraName, record) => <Input value={cameraName} onChange={(event) => onEditCamera(record.cameraId, event, 'camera')} />,
    },
    {
      title: 'Filename',
      dataIndex: 'filename',
      width: '30%',
      render: (filename, record) => <Input value={filename} onChange={(event) => onEditCamera(record.cameraId, event, 'filename')} />,
    },
    {
      title: '',
      dataIndex: 'operation',
      render: (text, record) => (
        <Popconfirm title="Are you sure you want to delete?" onConfirm={() => onDeleteCamera(record.cameraId)}>
          <Button icon={<DeleteOutlined />} />
        </Popconfirm>
      ),
    },
  ];

  // handleDelete = cameraId => {
  //   const dataSource = [...this.state.dataSource];
  //   this.setState({
  //     dataSource: dataSource.filter(item => item.cameraId !== cameraId),
  //   });
  // };

  // handleSave = row => {
  //   const newData = [...this.state.dataSource];
  //   const index = newData.findIndex(item => row.key === item.key);
  //   const item = newData[index];
  //   newData.splice(index, 1, { ...item, ...row });
  //   this.setState({
  //     dataSource: newData,
  //   });
  // };

  // const { dataSource } = this.state;
  // const components = {
  //   body: {
  //     row: EditableRow,
  //     cell: EditableCell,
  //   },
  // };
  // const columns = this.columns.map(col => {
  //   if (!col.editable) {
  //     return col;
  //   }

  //   return {
  //     ...col,
  //     onCell: record => ({
  //       record,
  //       editable: col.editable,
  //       dataIndex: col.dataIndex,
  //       title: col.title,
  //       handleSave: this.handleSave,
  //     }),
  //   };
  // });
  return (
    <div>
      <Button
        onClick={onAddCamera}
        // type="primary"
        style={{
          marginBottom: 16,
        }}
      >
        Add Camera
      </Button>

      <Table
        size="small"
        rowKey="cameraId"
        locale={{
          emptyText: 'No cameras have been setup for this location',
        }}
        // components={components}
        // rowClassName={() => 'editable-row'}
        bordered
        dataSource={location.cameras}
        columns={columns}
        pagination={false}
      />
    </div>
  );
};

export default CameraTable;
