import _ from 'lodash';
import React, { Component } from 'react';

import { getBinBankPreferences, setBinBankPreferences } from '../api';
import BinBankPreferencesPage from './BinBankPreferencesPage';
import BinBankPreferencesModal from './BinBankPreferencesModal';
import ErrorPage from '../Common/ErrorPage';

const initialFilters = {
  binBankDescription: [],
  mcGroupDescription: [],
  product: [],
  site: [],
  visible: [],
};

const initialState = {
  // True if the products or preferences are currently loading.
  loading: false,
  // An array of binBank/preferences data to be displayed as rows in a table.
  rows: [],
  // String containing
  error: null,
  selectedRowKeys: [],
  filters: { ...initialFilters },
  canClearFilters: false,
  products: [],
  // An array of binBankIds for the rows that are currently being saved.
  savingPreferences: [],

  multiEditOpen: false,
  multiEditColor: null,
  multiEditVisible: false,
};

class BinBankPreferencesController extends Component {
  constructor(props) {
    super(props);

    this.state = { ...initialState };

    this.onColorChange = this.onColorChange.bind(this);
    this.onClearFilterClick = this.onClearFilterClick.bind(this);
    this.onMultipleEditClick = this.onMultipleEditClick.bind(this);
    this.onMultiEditSaveClick = this.onMultiEditSaveClick.bind(this);
    this.onMultiEditCancelClick = this.onMultiEditCancelClick.bind(this);
    this.onMultipleEditClick = this.onMultipleEditClick.bind(this);
    this.onMultipleEditColorChange = this.onMultipleEditColorChange.bind(this);
    this.onMultipleEditVisibleClick = this.onMultipleEditVisibleClick.bind(this);
    this.onRefreshClick = this.onRefreshClick.bind(this);
    this.onRowSelectChange = this.onRowSelectChange.bind(this);
    this.onTableChange = this.onTableChange.bind(this);
    this.onProductChange = this.onProductChange.bind(this);
    this.onVisibleCheck = this.onVisibleCheck.bind(this);
  }

  componentDidMount() {
    this.loadPreferences();
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.selectedCompany.name !== prevProps.selectedCompany.name) {
      this.setState({ ...({ ...initialState }) }, () => {
        this.loadPreferences();
      });
    }
  }

  onClearFilterClick() {
    this.setState({
      filters: { ...initialFilters },
      canClearFilters: false,
      selectedRowKeys: [],
    });
  }

  onColorChange(binBankId, color) {
    const { rows } = this.state;
    const { visible } = rows.find((r) => r.binBankId === binBankId);

    this.setPreferences([binBankId], { color: color.hex, visible });
  }

  onMultipleEditClick() {
    const { selectedRowKeys, rows } = this.state;

    const firstBinBankId = selectedRowKeys[0];
    const firstBinBank = rows.find((x) => x.binBankId === firstBinBankId);

    this.setState({
      multiEditOpen: true,
      multiEditColor: firstBinBank.color,
      multiEditVisible: firstBinBank.visible,
    });
  }

  onMultipleEditVisibleClick(e) {
    this.setState({
      multiEditVisible: e.target.checked,
    });
  }

  onMultiEditSaveClick() {
    const { selectedRowKeys, multiEditColor, multiEditVisible } = this.state;
    this.setState({
      multiEditOpen: false,
    }, () => {
      this.setPreferences(selectedRowKeys, {
        color: multiEditColor,
        visible: multiEditVisible,
      });
    });
  }

  onMultiEditCancelClick() {
    this.setState({
      multiEditOpen: false,
    });
  }

  onMultipleEditColorChange(color) {
    this.setState({
      multiEditColor: color.hex,
    });
  }

  onProductChange(product) {
    const { filters } = this.state;
    const newFilters = { ...filters, product };

    this.setState({
      filters: newFilters,
      canClearFilters: !_.isEqual(newFilters, initialFilters),
      selectedRowKeys: [],
    });
  }

  onRefreshClick() {
    this.loadPreferences();
  }

  onRowSelectChange(selectedRowKeys) {
    this.setState({ selectedRowKeys });
  }

  onTableChange(_pagination, filters) {
    this.setState({
      filters,
      canClearFilters: !_.isEqual(filters, initialFilters),
      selectedRowKeys: [],
    });
  }

  onVisibleCheck(binBankId, checked) {
    const { rows } = this.state;
    const { color } = rows.find((r) => r.binBankId === binBankId);
    this.setPreferences([binBankId], { visible: checked, color });
  }

  setPreferences(binBankIds, body) {
    this.setState({
      savingPreferences: binBankIds,
      error: null,
    }, () => {
      const body2 = { color: body.color || '#ffffff', visible: body.visible || false };

      // Default to white if not specified
      setBinBankPreferences(this.props.selectedCompany.name, binBankIds, body2)
        .then(() => {
          const { rows } = this.state;
          const clonedRows = rows.slice(0); // Clone the array

          binBankIds.forEach((updateBinBankId) => {
            const binBankIndex = clonedRows.findIndex((r) => r.binBankId === updateBinBankId);
            const updatedBinBank = clonedRows[binBankIndex];
            updatedBinBank.visible = body.visible;
            updatedBinBank.color = body.color;
            // rows[binIndex] = bin;
          });

          this.setState({
            rows: clonedRows,
            savingPreferences: [],
          });
        })
        .catch((error) => {
          this.setState({
            error: error.body,
            savingPreferences: [],
          });
        });
    });
  }

  loadPreferences() {
    this.setState({
      loading: true,
      error: null,
    }, async () => {
      getBinBankPreferences(this.props.selectedCompany.name)
        .then((rows) => {
          const products = _.sortBy(_.uniq(_.map(rows, 'product')));

          this.setState({
            loading: false,
            rows,
            products,
          });
        }).catch((error) => {
          this.setState({
            loading: false,
            rows: [],
            error: error.body,
          });
        });
    });
  }

  render() {
    const {
      multiEditColor, multiEditOpen, multiEditVisible, selectedRowKeys,
    } = this.state;

    return (
      <>
        <ErrorPage error={this.state.error}>
          <BinBankPreferencesModal
            color={multiEditColor}
            modalVisible={multiEditOpen}
            preferencesCount={selectedRowKeys.length}
            visible={multiEditVisible}
            onColorChange={this.onMultipleEditColorChange}
            onVisibleChange={this.onMultipleEditVisibleClick}
            onSaveClick={this.onMultiEditSaveClick}
            onCancelClick={this.onMultiEditCancelClick}
          />
          <BinBankPreferencesPage
            {...this.state}
            onClearFilterClick={this.onClearFilterClick}
            onColorChange={this.onColorChange}
            onMultipleEditClick={this.onMultipleEditClick}
            onProductChange={this.onProductChange}
            onRefreshClick={this.onRefreshClick}
            onRowSelectChange={this.onRowSelectChange}
            onTableChange={this.onTableChange}
            onVisibleCheck={this.onVisibleCheck}
          />
        </ErrorPage>
      </>
    );
  }
}

export default BinBankPreferencesController;
