import React from 'react';
import { Button, Tooltip } from 'antd';
import ButtonGroup from 'antd/lib/button/button-group';
import { FilterOutlined, FilterTwoTone, ReloadOutlined } from '@ant-design/icons';
import RadioButtonGroup from '../Common/RadioButtonGroup';

const BinBankPreferencesFilters = ({
  canClearFilters,
  products,
  filters,
  selectedRowKeys,

  onClearFilterClick,
  onMultipleEditClick,
  onProductChange,
  onRefreshClick,
}) => (
  <div style={{ marginBottom: 16, height: '32px' }}>
    <div style={{ float: 'left', width: 800 }}>
      <RadioButtonGroup
        options={products}
        selectedOptions={filters.product}
        onChange={onProductChange}
        style={{ marginRight: 16 }}
      />
      <ButtonGroup>
        <Tooltip title="Clear All Filters">
          <Button disabled={!canClearFilters} onClick={onClearFilterClick}>
            {canClearFilters && <FilterTwoTone twoToneColor="red" />}
            {!canClearFilters && <FilterOutlined />}
          </Button>
        </Tooltip>
        <Tooltip title="Refresh Data">
          <Button onClick={onRefreshClick}>
            <ReloadOutlined />
          </Button>
        </Tooltip>
      </ButtonGroup>
    </div>
    <div style={{ float: 'right' }}>
      <span>
        {selectedRowKeys.length > 0 ? `Selected ${selectedRowKeys.length} Bin Banks` : ''}
      </span>
      <Button type="primary" disabled={selectedRowKeys.length === 0} style={{ marginLeft: 8 }} onClick={onMultipleEditClick}>
        Edit Multiple
      </Button>

    </div>
    <div className="clear" />
  </div>
);

export default BinBankPreferencesFilters;
