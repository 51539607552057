import _ from 'lodash';
import React, { Component } from 'react';
import { Auth } from 'aws-amplify';

import AccountPage from './AccountPage';
import { resetPassword, getTruckerUsers } from '../api';

class AccountController extends Component {
  constructor(props) {
    super(props);

    this.state = {
      savingDispatch: false,
      savedDispatch: false,
      loading: false,
      error: null,
      accounts: [],
    };

    this.onResetDispatchClick = this.onResetDispatchClick.bind(this);
  }

  componentDidMount() {
    this.loadUsers();
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedCompany.name !== prevProps.selectedCompany.name) {
      this.loadUsers();
    }
  }

  onResetDispatchClick(username) {
    this.setState({
      savedDispatch: false,
      loading: true,
      error: null,
    }, () => {
      resetPassword(this.props.selectedCompany.name, username)
        .then(() => {
          this.setState({
            error: null,
            savedDispatch: true,
            loading: false,
          });
        })
        .catch((error) => {
          this.setState({
            error: error.body,
            loading: false,
          });
        });
    });
  }

  loadUsers() {
    this.setState({
      loading: true,
      error: null,
    }, async () => {
      const user = await Auth.currentAuthenticatedUser({ bypassCache: true });

      getTruckerUsers(this.props.selectedCompany.name)
        .then((accounts) => {
          // Filter out the current account.
          accounts = accounts.filter((x) => x.username !== user.username);
          accounts = accounts.map((a) => ({ ...a, canReset: a.status === 'CONFIRMED' || a.status === 'CURRENT USER' }));
          accounts = _.orderBy(accounts, ['username']);
          this.setState({
            error: null,
            loading: false,
            accounts,
          });
        })
        .catch((error) => {
          this.setState({
            error: error.body,
            loading: false,
          });
        });
    });
  }

  render() {
    return (
      <AccountPage
        {...this.state}
        onResetDispatchClick={this.onResetDispatchClick}
      />
    );
  }
}

export default AccountController;
