import { Row, Col } from 'antd';
import React from 'react';

import Chart from './Chart';
import ChartMenu from './ChartMenu';
import ChartStats from './ChartStats';

const ChartPage = (params) => {
  const {
    chartError,
    chartLoaded,
    chartLoading,
    data,
    date,
    endIndex,
    hierarchy,
    hierarchyLoading,
    lineMeta,
    liveView,
    onBinBankChange,
    onBrushChange,
    onDateChange,
    onLiveChange,
    selectedBinBank,
    startIndex,
    stats,
    statsLoaded,
    statsLoading,
    timerRemaining,
    timezone,
    // bins,
    // minDomain,
    // maxDomain,
    // xticks,
  } = params;

  return (
    <div className="chart-page">
      <Row className="row-margin-bottom">
        <Col xs={24}>
          <Row gutter={16}>
            <Col xs={24} lg={12} xxl={{ span: 10 }}>
              <ChartMenu
                date={date}
                hierarchy={hierarchy}
                hierarchyLoading={hierarchyLoading}
                liveView={liveView}
                onBinBankChange={onBinBankChange}
                onDateChange={onDateChange}
                onLiveChange={onLiveChange}
                selectedBinBank={selectedBinBank}
                timerRemaining={timerRemaining}
              />
            </Col>
            <Col xs={24} lg={12} xxl={{ span: 10, offset: 4 }}>
              <ChartStats
                liveView={liveView}
                selectedBinBank={selectedBinBank}
                stats={stats}
                statsLoaded={statsLoaded}
                statsLoading={statsLoading}
                timezone={timezone}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Chart
        chartError={chartError}
        chartLoaded={chartLoaded}
        chartLoading={chartLoading}
        data={data}
        lineMeta={lineMeta}
        onBrushChange={onBrushChange}
        startIndex={startIndex}
        endIndex={endIndex}
        // bins={bins}
        // minDomain={minDomain}
        // maxDomain={maxDomain}
        // xticks={xticks}
      />
    </div>
  );
};

export default ChartPage;
