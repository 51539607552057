import { Table, Progress, Tag } from 'antd';
import _ from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import { Link } from 'react-router-dom';
import { Sparklines, SparklinesLine } from 'react-sparklines';

class ProductSegment extends React.Component {
  shouldComponentUpdate(nextProps) {
    return !_.isEqual(this.props.products, nextProps.products) || !_.isEqual(this.props.timezoneType, nextProps.timezoneType);
  }

  render() {
    const {
      timezone, products, localTimezone, timezoneType,
    } = this.props;
    const columns = [
      {
        title: 'Product',
        dataIndex: 'product',
        className: 'align-center-column',
        width: 70,
        render(product, record) {
          const almostFull = record.productPercentM3 >= 0.8;
          const almostFullDisplay = almostFull ? (
            <>
              <br />
              {`${Math.round(record.productPercentM3 * 100)}%`}
            </>
          ) : '';
          const className = almostFull ? 'blink_me' : null;
          return {
            props: {
              rowSpan: record.productRowSpan,
            },
            children: <div className={className}>
              {product}
              {almostFullDisplay}
              </div>, // eslint-disable-line
          };
        },
      },
      {
        title: 'Bin Bank',
        dataIndex: 'binBankDescription',
        width: '15%',
        render(binBankDescription, record) {
          return {
            props: {
              style: { background: record.color || 'white' },
              rowSpan: record.binBankRowSpan,
            },
            children: <div>{binBankDescription}</div>,
          };
        },
      },
      {
        title: 'Est. Full',
        dataIndex: 'estimatedTimeFullUtc',
        width: 100,
        className: 'align-center-column',
        render(estimatedTimeFullUtc, record) {
          let data;

          const tz = (timezoneType === 'Local') ? localTimezone : timezone;

          if (!estimatedTimeFullUtc) {
            data = '-';
          } else {
            const mom = moment(estimatedTimeFullUtc).tz(tz);

            const durr = moment.duration(mom.diff(moment()));
            const h = durr.hours() > 0 ? `${durr.hours()}h ` : '';
            const m = `${durr.minutes()}m`;
            // const s = `${durr.seconds()}s`;

            const ttr = `${h}${m}`;

            data = (
              <>
                <span>{mom.format('hh:mm A z')}</span>
                {/* <br />
                <span>{mom.format('z')}</span> */}
                <br />
                <span>
                  (
                  {ttr}
                  )
                </span>
              </>
            );
          }

          return {
            props: {
              style: { background: record.color || 'white' },
              rowSpan: record.binBankRowSpan,
            },
            children: <span style={{ whiteSpace: 'nowrap' }}>{data}</span>,
          };
        },
      },
      {
        title: 'Bin',
        dataIndex: 'bin',
        width: '25%',
        render(bin, record) {
          const age = moment().diff(moment(record.binDate), 'minutes');
          console.log('BinDate', record.binDate, age);
          const old = age >= 15 ?
            <span style={{ float: 'right' }}><Tag style={{ color: 'white' }} className="blink_me offline-tag">Old</Tag></span> :
            null;

          return {
            props: {
              style: { background: record.color || 'white' },
            },
            children: <div>
              {bin}
              {old}
            </div>,
          };
        },
      },

      {
        title: 'Full',
        dataIndex: 'full',
        width: '25%',
        // width: 125,
        render: (full) => {
          let strokeColor;
          const percent = Math.round(full);
          if (percent < 40) {
            strokeColor = '#1CAF83';
          } else if (percent < 80) {
            strokeColor = '#FFCF29';
          } else {
            strokeColor = '#E95100';
          }

          return {
            children: <Progress percent={percent} size="small" strokeLinecap="square" strokeColor={strokeColor} strokeWidth={12} status="normal" style={{ paddingRight: '8px' }} />,
          };
        },
      },

      {
        title: 'Trend (4hr)',
        dataIndex: 'fullTrend',
        width: '30%',
        render(fullTrend, record) {
          return {
            children: (
              <Link to={`chart/${record.binBankId}`}>
                <Sparklines data={fullTrend} min={0} max={100} margin={0} style={{ border: '1px solid #f5f5f5', height: '30px', width: '100%' }}>
                  <SparklinesLine style={{ strokeWidth: '3px', stroke: '#2767ad' }} />
                </Sparklines>
              </Link>
            ),
          };
        },
      },
    ];

    const dataSource = _.flattenDeep(
      products.map((p) => p.binBanks.map((bb) => bb.bins.map((b) => ({
        productPercentM3: p.percentM3,
        product: p.product,
        binBankDescription: bb.binBankDescription,
        binId: b.binId,
        bin: b.bin,
        binDate: b.currentDateUtc,
        full: b.currentPercent,
        estimatedTimeFull: bb.estimatedTimeFull,
        estimatedTimeFullUtc: bb.estimatedTimeFullUtc,
        fullTrend: b.trendPercent,
        color: b.color,
        binBankId: bb.binBankId,
        productRowSpan: 1,
        binBankRowSpan: 1,
      })))),
    );

    let lastProductDataSource = null;
    let lastBinBankDataSource = null;

    for (let i = 0; i < dataSource.length; i += 1) {
      const d = dataSource[i];

      if (lastProductDataSource && lastProductDataSource.product === d.product) {
        lastProductDataSource.productRowSpan += 1;
        d.productRowSpan = 0;
      } else {
        lastProductDataSource = d;
      }

      if (lastBinBankDataSource && lastBinBankDataSource.binBankId === d.binBankId) {
        lastBinBankDataSource.binBankRowSpan += 1;
        d.binBankRowSpan = 0;
      } else {
        lastBinBankDataSource = d;
      }
    }

    return (
      <div className="dispatch-product-table">
        <Table rowKey="binId" bordered className="test" columns={columns} dataSource={dataSource} size="small" pagination={false} />
      </div>
    );
  }
}

export default ProductSegment;
