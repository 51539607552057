import { Popover } from 'antd';
import React, { Component } from 'react';
import { GithubPicker } from 'react-color';

const COLORS = ['#B80000', '#DB3E00', '#FCCB00', '#008B02', '#006B76', '#1273DE', '#004DCF', '#FFFFFF', '#EB9694', '#FAD0C3', '#FEF3BD', '#C1E1C5', '#BEDADC', '#C4DEF6', '#BED3F3', '#D4C4FB'];

class ColourPopover extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
    };

    this.onColorChange = this.onColorChange.bind(this);
    this.onVisibleChange = this.onVisibleChange.bind(this);
  }

  onColorChange(color) {
    const { onColorChange } = this.props;

    this.setState({
      visible: false,
    }, () => {
      onColorChange(color);
    });
  }

  onVisibleChange(visible) {
    this.setState({ visible });
  }

  render() {
    const { color } = this.props;
    const { visible } = this.state;
    const content = <GithubPicker width="215px" triangle="hide" color={color || '#fff'} onChangeComplete={this.onColorChange} colors={COLORS} />;

    return (
      <>

        <Popover visible={visible} content={content} trigger="click" placement="left" onVisibleChange={this.onVisibleChange}>
          <div style={{
            width: 48, height: 24, backgroundColor: color, border: '1px solid rgb(232, 232, 232)',
          }}
          />
        </Popover>
      </>
    );
  }
}

export default ColourPopover;
