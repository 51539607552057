import _ from 'lodash';
import React, { Component } from 'react';

import { getLocationPreferences, setLocationPreferences } from '../api';
import LocationPreferencesPage from './LocationPreferencesPage';
import ErrorPage from '../Common/ErrorPage';

const initialFilters = {
  visible: null,
};

const initialState = {
  canClearFilters: false,
  dirty: false,
  error: null,
  filters: { ...initialFilters },
  loading: false,
  saving: false,
  clearRows: [],
  rows: [],
};

class LocationPreferencesController extends Component {
  constructor(props) {
    super(props);

    this.state = { ...initialState };

    this.onClearFilterClick = this.onClearFilterClick.bind(this);
    this.onSortChange = this.onSortChange.bind(this);
    this.onRefreshClick = this.onRefreshClick.bind(this);
    this.onTableChange = this.onTableChange.bind(this);
    this.onVisibleCheck = this.onVisibleCheck.bind(this);
    this.onSaveClick = this.onSaveClick.bind(this);
  }

  componentDidMount() {
    this.loadPreferences();
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.selectedCompany.name !== prevProps.selectedCompany.name) {
      this.setState({ ...({ ...initialState }) }, () => {
        this.loadPreferences();
      });
    }
  }

  onClearFilterClick() {
    this.setState({
      filters: { ...initialFilters },
      canClearFilters: false,
      selectedRowKeys: [],
    });
  }

  onSortChange(locationId, sort) {
    const { rows } = this.state;
    const { visible } = rows.find((r) => r.locationId === locationId);

    this.setPreferences(locationId, { sort, visible });
  }

  onRefreshClick() {
    this.loadPreferences();
  }

  onTableChange(_pagination, filters) {
    this.setState({
      filters,
      canClearFilters: !_.isEqual(filters, initialFilters),
    });
  }

  onVisibleCheck(locationId, checked) {
    const { rows } = this.state;
    const { sort } = rows.find((r) => r.locationId === locationId);
    const newSort = checked ? sort : null; // If unchecking, then remove sort value
    this.setPreferences(locationId, { visible: checked, sort: newSort });
  }

  setPreferences(locationId, body) {
    const rows = this.state.rows.map((r) => {
      if (r.locationId !== locationId) {
        return r;
      }
      return { ...r, ...body };
    });

    this.setState({
      rows,
      dirty: !_.isEqual(rows, this.state.cleanRows),
    });
  }

  onSaveClick() {
    this.setState({
      error: null,
      saving: true,
    }, () => {
      const body = this.state.rows.map((r) => ({
        locationId: r.locationId,
        sort: r.sort || null,
        visible: r.visible || false,
      }));
      setLocationPreferences(this.props.selectedCompany.name, body)
        .then(() => {
          this.setState({ saving: false }, () => {
            this.loadPreferences();
          });
        })
        .catch((error) => {
          this.setState({
            error: error.body,
            saving: false,
          });
        });
    });
  }

  loadPreferences() {
    this.setState({
      loading: true,
      error: null,
    }, async () => {
      getLocationPreferences(this.props.selectedCompany.name)
        .then((rows) => {
          this.setState({
            loading: false,
            rows,
            cleanRows: [...rows],
            dirty: false,
          });
        }).catch((error) => {
          this.setState({
            loading: false,
            rows: [],
            cleanRows: [],
            dirty: false,
            error: error.body,
          });
        });
    });
  }

  render() {
    const {
      multiEditColor, multiEditOpen, multiEditVisible, selectedRowKeys,
    } = this.state;

    return (
      <>
        <ErrorPage error={this.state.error}>
          <LocationPreferencesPage
            {...this.state}
            onClearFilterClick={this.onClearFilterClick}
            onSortChange={this.onSortChange}
            onRefreshClick={this.onRefreshClick}
            onTableChange={this.onTableChange}
            onVisibleCheck={this.onVisibleCheck}
            onSaveClick={this.onSaveClick}
          />
        </ErrorPage>
      </>
    );
  }
}

export default LocationPreferencesController;
