import { Menu, Dropdown } from 'antd';
import React from 'react';
import { IdcardOutlined } from '@ant-design/icons';

const { SubMenu, ItemGroup } = Menu;

const CompanySelector = (props) => {
  const { selectedCompany, companies, onCompanySelect } = props;
  // if (companies.length <= 1) {
  //   return <span>{selectedCompany.title}</span>;
  // }

  const menuItems = companies.map((c) => <Menu.Item key={`company_${c.key}`}>{c.title}</Menu.Item>);

  return (
    <SubMenu
      {...props}
      title={(
        <span className="submenu-title-wrapper">
          <IdcardOutlined />
          {selectedCompany.title}
        </span>
)}
    >
      {menuItems}
    </SubMenu>
  );

  // const menu = (
  //   <Menu onClick={onCompanySelect}>
  //     {menuItems}
  //   </Menu>
  // );

  // return (
  //   <Dropdown overlay={menu}>
  //     <span>
  //       {selectedCompany.title}
  //       {' '}
  //       <Icon type="down" />
  //     </span>
  //   </Dropdown>
  // );
};

export default CompanySelector;
// import { Menu, Icon, Dropdown } from 'antd';
// import React from 'react';

// const CompanySelector = ({ selectedCompany, companies, onCompanySelect }) => {
//   if (companies.length <= 1) {
//     return <span>{selectedCompany.title}</span>;
//   }

//   const menuItems = companies.map(c => <Menu.Item key={c.key}>{c.title}</Menu.Item>);

//   const menu = (
//     <Menu onClick={onCompanySelect}>
//       {menuItems}
//     </Menu>
//   );

//   return (
//     <Dropdown overlay={menu}>
//       <span>
//         {selectedCompany.title}
//         {' '}
//         <Icon type="down" />
//       </span>
//     </Dropdown>
//   );
// };

// export default CompanySelector;
