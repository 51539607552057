import React from 'react';
import {
  Col, Row, Button, Select, Tabs, Card,
} from 'antd';

import { CalendarOutlined, SettingOutlined } from '@ant-design/icons';
import LocationSchedulePage from './LocationSchedulePage';
import LocationSetupPage from './LocationSetupPage';

const { Option } = Select;
const { TabPane } = Tabs;

const LocationPage = (props) => {
  const {
    loading,
    locationId,
    location,
    locations,
    onLocationChange,
    onCreateLocation,
    tabKey,
    onTabChange,
  } = props;

  const locationOptions = locations.map((loc) => <Option key={loc.locationId} value={loc.locationId}>{loc.locationName}</Option>);

  const header = (
    <>
      <span style={{ marginRight: 8 }}>Location:</span>
      <Select
        showSearch
        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        notFoundContent="No locations have been created."
        loading={loading}
        value={locationId}
        style={{ width: 240 }}
        onChange={onLocationChange}
      >
        { locationOptions }
      </Select>
    </>
  );

  return (
    <>
      <Card title={header} style={{ marginBottom: 16 }} extra={<Button visible={false} type="primary" onClick={onCreateLocation}>Create Location</Button>}>
        {location
          && (
            <Card loading={loading} title={location.locationName} size="small">
              <Tabs activeKey={tabKey} onChange={onTabChange}>
                <TabPane
                  tab={(
                    <span>
                      <SettingOutlined />
                      Setup
                    </span>
)}
                  key="setup"
                >
                  <LocationSetupPage {...props} />
                </TabPane>
                <TabPane
                  disabled={locationId === null}
                  tab={(
                    <span>
                      <CalendarOutlined />
                      Schedule
                    </span>
)}
                  key="schedule"
                >
                  <LocationSchedulePage {...props} />
                </TabPane>
              </Tabs>
            </Card>
          )}
      </Card>

    </>
  );
};

export default LocationPage;
